import { setFreeWheelWVideoParamCookie } from 'lib/videoParamsCookie';

/**
 * @typedef {ReturnType<import('@nbcnews/gpp-utilities').useConsentStatus>["data"]} ConsentStatus
 */

/**
 * @module getFreewheelConfig
 * @param {object} param
 * @param {number} param.width
 * @param {number} param.height
 * @param {ConsentStatus} param.gppStatus
 * @returns {Record<string, string>}
 */
export function getFreewheelCustomSection({ width, height, gppStatus }) {
  const custom = {
    _fw_vcid2: setFreeWheelWVideoParamCookie(),
    _fw_player_width: Math.round(width).toString(),
    _fw_player_height: Math.round(height).toString(),
  };

  if (gppStatus) {
    Object.assign(custom, {
      _fw_sid: gppStatus?.gppString,
      _fw_gpp: gppStatus?.applicableSections?.join(','),
    });
  }

  return custom;
}
