import { getConfigFromMpxData } from '@nbcnews/gamma';
import { getFreewheelCustomSection } from './getFreewheelCustomSection';
/**
 * @typedef {import('@nbcnews/gamma').PlaylistItemSource} PlaylistItemSource
 * @typedef {import('@nbcnews/gamma').FreewheelAdConfig} FreewheelAdConfig
 */

/**
 * @param {object} config
 * @param {string} config.imageUrl
 * @param {string} config.videoId
 * @param {string} config.fwassetid
 * @param {string} [config.webvttUrl]
 * @param {string} [config.srtUrl]
 * @param {boolean} [config.useMpxParams]
 * @param {import('./getFreewheelCustomSection').ConsentStatus} config.gppStatus
 * @param {Partial<FreewheelAdConfig>} [config.adConfig]
 * @param {import('./selectVideoData').VideoData["videoAssets"]} config.videoAssets
 * @returns {import('./selectVideoData').PlaylistItem}
 */
export function toGammaPlaylistItem({
  gppStatus,
  imageUrl,
  videoId,
  fwassetid,
  videoAssets,
  webvttUrl,
  srtUrl,
  useMpxParams,
  adConfig,
}) {
  const captionUrl = webvttUrl ?? srtUrl;
  return {
    title: '',
    description: '',
    image: imageUrl,
    mediaid: videoId,
    duration: videoAssets[0]?.assetDuration && Math.floor(videoAssets[0].assetDuration),
    fwassetid,
    tracks: captionUrl
      ? [
        {
          file: captionUrl,
          label: 'English', // No way to detect language yet, by brand maybe?
          kind: 'captions',
        },
      ]
      : [],
    sources: videoAssets.map((asset) => {
      /** @type {PlaylistItemSource} */
      const config = getConfigFromMpxData(asset.publicUrl, { hls: false });

      if (useMpxParams && asset?.assetType === 'EMP' && asset?.format === 'M3U') {
        const url = new URL(config.file);
        url.searchParams.append('formats', 'M3U');
        url.searchParams.append('manifest', 'M3U');
        url.searchParams.append('format', 'redirect');
        config.file = url.toString();
      }

      config.file = config.file.replace(/^https?:/, '');
      config.type = 'hls';
      config.duration = Math.floor(asset.assetDuration);
      return config;
    }),
    freewheel({ height, width }) {
      const mpid = global.BI?.userInfo?.get('mpid');
      /** @type {Partial<FreewheelAdConfig["freewheel"]>} */
      const fwObject = {
        sectionid: adConfig?.freewheel?.sectionid,
        custom: getFreewheelCustomSection({ gppStatus, height, width }),
      };
      if (mpid) {
        fwObject.vcid = mpid;
      }
      return fwObject;
    },
  };
}
