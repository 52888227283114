import getConfig from 'next/config';

export const ndpOrigin = 'https://media1.s-nbcnews.com';

const ndpPathname = '/i/videoassets/ndp/latest/';

export function chooseNDPScript() {
  const {
    publicRuntimeConfig: {
      // URL to latest NDP script
      NDP_URL: ndpURL = `${ndpOrigin}${ndpPathname}`,
      NDP_HLS_BUILD = 'ndp-hls',
    },
  } = getConfig();

  let {
    publicRuntimeConfig: {
      NDP_BASE_BUILD: playerBuild = 'ndp',
    },
  } = getConfig();

  // Init vars to test browser capabilities that determine what NDP build to load
  const MediaSource = window.MediaSource || window.WebKitMediaSource;
  const video = document.createElement('video');

  // Test browser capabilities, and return appropriate build
  if (MediaSource && !video.canPlayType('application/x-mpegURL').length) {
    playerBuild = NDP_HLS_BUILD;
  }

  return `${ndpURL}${playerBuild}.js`;
}
