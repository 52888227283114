import { useEffect, useState, useRef } from 'react';
import { useInactivityMonitorUpdate } from 'lib/Hooks/useInactivityMonitorUpdate';
import { useVideoPlayCoordinator } from 'lib/Hooks/useVideoPlayCoordinator';
import { useAutoplayCapabilities } from './useAutoplayCapabilities';

/**
 * @typedef {import('@nbcnews/gamma').GammaProps} GammaProps
 * @typedef {GammaProps["playlistItem"]} PlaylistItem
 */

/**
 * @summary Manages video player control state
 * @description Centralizes the video player state.  This state is tied to
 * the props that the Gamma component accepts currently, but its purpose is
 * general and Ramen-agnostic.  All properties of state can be overridden by
 * passing your own props to Gamma as needed.
 *
 * @typedef {object} VideoControlOptions
 * @property { boolean | "muted" } [VideoControlOptions.autoPlay] initial setting for `isPaused`.
 * Changes to this value in future renders are ignored.
 * @property {Partial<{
 *   onPauseChange: GammaProps["onPauseChange"],
 *   onAdImpression: GammaProps["onAdImpression"],
 *   onError: GammaProps["onError"],
 *   onPlaylistItem: GammaProps["onPlaylistItem"],
 * }>} [VideoControlOptions.relayEvents] event handlers that can be called with changes.
 * These can be useful if some further actions need to occur farther up the component tree,
 * but should be avoided where it's practical to lift this state hook up instead.
 *
 * @param {VideoControlOptions} options
 *
 * @returns {{ state: Omit<GammaProps, 'playlistItem' | 'jwPlayerLibraryUrl' | 'jwPlayerLibraryKey'> & {
 *   adPlaying: false | { duration : number};
 *   hasPlaybackStarted: boolean;
 *   time: number;
 * }, control: { seek: (position: number) => void; }}}
 */
export function useVideoControl(options) {
  const ref = useRef({ seek() {}, closeFloat() {} });
  const { autoPlay, relayEvents = {} } = options;
  const wasAutoPlayAttempted = useRef(false);
  const [isMuted, setIsMuted] = useState(!!autoPlay);
  const [time, setTime] = useState(0);
  const [hasPlaybackStarted, setHasPlaybackStarted] = useState(false);
  const [adPlaying, setAdPlaying] = useState(
    /** @type {ReturnType<typeof useVideoControl>["state"]["adPlaying"]} */ (false),
  );
  const [isVisible, setIsVisible] = useState(false);

  const {
    isPaused, otherPlayerIsPlaying, requestPlay, play, pause,
  } = useVideoPlayCoordinator();

  useInactivityMonitorUpdate(!isPaused && isVisible);

  // Float disabling like this is temporary.  Currently JW Player doesn't offer enough control over
  // its behavior.  As a result, when we close the float like this, it cannot be re-opened.
  const floatOff = hasPlaybackStarted && otherPlayerIsPlaying;
  useEffect(() => {
    if (floatOff) {
      ref.current.closeFloat();
    }
  }, [floatOff]);

  const { canAutoplayMuted, canAutoplayUnmuted } = useAutoplayCapabilities(!!autoPlay);

  const autoPlayMuted = autoPlay === 'muted' || !canAutoplayUnmuted;

  useEffect(() => {
    if (canAutoplayMuted && !wasAutoPlayAttempted.current) {
      setIsMuted(autoPlayMuted);
    }
  }, [canAutoplayMuted, autoPlayMuted]);

  useEffect(() => {
    if (canAutoplayMuted && isMuted === autoPlayMuted && !wasAutoPlayAttempted.current) {
      wasAutoPlayAttempted.current = true;
      requestPlay();
    }
  }, [canAutoplayMuted, isMuted, autoPlayMuted, requestPlay]);

  /** @type {ReturnType<(typeof useVideoControl)>["state"]} */
  const props = {
    onPauseChange(newValue) {
      if (newValue !== isPaused) {
        (newValue ? pause : play)();
      }
    },
    isPaused,
    onMuteChange: setIsMuted,
    isMuted,
    onTime: () => {},
    onAdTime({ duration, time: newTime }) {
      setAdPlaying({ duration });
      setTime(newTime);
    },
    onFirstFrame() {
      setHasPlaybackStarted(true);
    },
    onPlaylist() {
      setHasPlaybackStarted(false);
    },
    onPlaylistItem() {
      setHasPlaybackStarted(false);
    },
    onAdImpression({ duration }) {
      setHasPlaybackStarted(true);
      setAdPlaying({ duration });
    },
    onAdBreakEnd() {
      setAdPlaying(false);
    },
    ref,
    time,
    adPlaying,
    onViewable({ viewable }) {
      setIsVisible(!!viewable);
    },
    hasPlaybackStarted,
  };

  Object.keys(relayEvents).forEach((key) => {
    // @ts-expect-error
    const wrapped = props[key];
    // @ts-expect-error
    props[key] = (...args) => {
      try {
        if (wrapped) wrapped(...args);
      } finally {
        // @ts-expect-error
        if (relayEvents[key]) relayEvents[key](...args);
      }
    };
  });

  return {
    state: props,
    control: {
      seek: ref.current.seek,
    },
  };
}
