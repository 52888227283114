/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
import { useRef, useMemo, useCallback } from 'react';
import { useVideoCurrentPlayerStore } from 'store/videoCurrentPlayer';

/** @typedef {{
  isPaused: true;
  isPlaying: false;
  reason: 'preempted' | 'not-allowed' | 'paused';
} | {
  isPaused: false;
  isPlaying: true;
  reason: 'forced' | 'allowed';
}} State */

/** @typedef {{
  otherPlayerIsPlaying: boolean;
  requestPlay: () => void;
  play: () => void;
  pause: () => void;
} & State} VideoPlayerCoordinator */

/** @typedef {undefined | 'requested' | 'play' | 'pause'} CallState */

let counter = 0;

/**
 * Coordinates player states to ensure only one player is playing at a time.
 * @returns {VideoPlayerCoordinator}
 */
export function useVideoPlayCoordinator() {
  const componentId = useRef(++counter).current;
  /** @type {React.Ref<CallState>} */
  const callState = useRef(undefined);
  /** @type {React.Ref<boolean | undefined>} */
  const lastRequestSuccess = useRef(undefined);

  const {
    request, force, release, currentPlayerId,
  } = useVideoCurrentPlayerStore();

  const requestPlay = useCallback(() => {
    if (callState.current !== 'play') {
      callState.current = 'requested';
    }
    lastRequestSuccess.current = request(componentId);
  }, [request, componentId]);

  const play = useCallback(() => {
    callState.current = 'play';
    force(componentId);
  }, [force, componentId]);

  const pause = useCallback(() => {
    callState.current = 'pause';
    release(componentId);
  }, [release, componentId]);

  const state = getState(
    componentId !== currentPlayerId,
    callState.current,
    lastRequestSuccess.current,
  );

  const result = {
    ...state,
    otherPlayerIsPlaying: currentPlayerId !== undefined && currentPlayerId !== componentId,
    requestPlay,
    play,
    pause,
  };

  // Memoized just to be sure that downstream components don't run into some unexpected
  // renders that they aren't set up to handle.
  return useMemo(() => result, Object.values(result));
}

/**
 * @param {boolean} isPaused
 * @param {CallState} callState
 * @param {boolean | undefined} lastRequestSuccess
 * @returns {State}
 */
function getState(isPaused, callState, lastRequestSuccess) {
  function getReason() {
    if (isPaused) {
      if (callState === 'requested' && lastRequestSuccess === false) {
        return 'not-allowed';
      }
      if (!callState || callState === 'pause') {
        return 'paused';
      }
      return 'preempted';
    }
    if (callState === 'requested') {
      return 'allowed';
    }
    return 'requested';
  }
  return {
    isPaused,
    isPlaying: !isPaused,
    reason: getReason(),
  };
}
