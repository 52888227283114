/* eslint-disable dot-notation */
import defaultsDeep from 'lodash/defaultsDeep';
import MobileDetection from 'lib/MobileDetection';
import { useVertical } from 'lib/Hooks/useVertical';
import { v4 as uuidv4 } from 'uuid';
import { getBrandAdConfig } from './getBrandAdConfig';
import { getFreewheelCustomSection } from './getFreewheelCustomSection';

/**
 * @typedef {import('./getBrandAdConfig').FreewheelAdConfig} FreewheelAdConfig
 */

/**
 * @param {object} AdvertisingConfigArgs
 * @param {import('./getFreewheelCustomSection').ConsentStatus} AdvertisingConfigArgs.gppStatus
 * @param {boolean} AdvertisingConfigArgs.isLiveVideo
 * @param {string} AdvertisingConfigArgs.publisherName
 * @param {string} AdvertisingConfigArgs.vertical
 * @param {boolean} AdvertisingConfigArgs.isMobile
 * @param {boolean} AdvertisingConfigArgs.isEmbedded
 * @param {string} AdvertisingConfigArgs.mparticleId
 * @param {number} AdvertisingConfigArgs.playerWidth
 * @param {number} AdvertisingConfigArgs.playerHeight
 * @returns {FreewheelAdConfig}
 */
export function getAdvertisingConfig({
  gppStatus,
  isLiveVideo,
  publisherName,
  vertical,
  isMobile,
  isEmbedded,
  mparticleId,
  playerWidth,
  playerHeight,
}) {
  // if isMobile not set, detect
  // eslint-disable-next-line no-param-reassign
  isMobile = isMobile ?? MobileDetection.any();

  /** @type {FreewheelAdConfig} */
  const ramenAdvertisingConfig = {
    client: 'freewheel',
    freewheel: {
      networkid: 169843,
      serverid: 'https://29773.v.fwmrm.net/ad/g/1',
      profileid: '169843:nbcu_web_jwp_cs_moat_https',
      adManagerURL: 'https://mssl.fwmrm.net/libs/adm/7.2.1/AdManager.js',
      vcid: mparticleId,
      resp: 'json',
      custom: getFreewheelCustomSection({ width: playerWidth, height: playerHeight, gppStatus }),
    },
    adscheduleid: '12345',
    schedule: {
      adbreak: {
        offset: 'pre',
        tag: `placeholder_preroll_${publisherName}`,
      },
    },
    vpaidcontrols: true,
  };

  // Compose advertising config from the pieces.
  // Later objects act as defaults if earlier objects do not have the value already set.
  const advertisingConfig = defaultsDeep(
    {},
    getBrandAdConfig({
      isMobile,
      vertical,
      isEmbedded,
      isLiveVideo,
    }),
    ramenAdvertisingConfig,
  );

  return advertisingConfig;
}

/**
 * @param {object} AdvertisingConfigArgs
 * @param {boolean} AdvertisingConfigArgs.isLiveVideo
 * @param {string} AdvertisingConfigArgs.publisherName
 * @param {boolean} AdvertisingConfigArgs.isEmbedded
 * @param {import('./getFreewheelCustomSection').ConsentStatus} AdvertisingConfigArgs.gppStatus
 * @returns {(args: { width: number, height: number }) => FreewheelAdConfig}
 */
export function useAdConfigurator({
  publisherName = 'unknown',
  isEmbedded = false,
  isLiveVideo,
  gppStatus,
}) {
  const { vertical } = useVertical();

  // adConfigurator function
  return ({ width, height }) => {
    const mparticleId = global.BI?.userInfo?.get('mpid') || uuidv4(); // NGLIFEW-1201 refactor to use store

    const isMobile = MobileDetection.any();

    return getAdvertisingConfig({
      gppStatus,
      isLiveVideo,
      publisherName,
      isMobile,
      isEmbedded,
      mparticleId,
      vertical,
      playerWidth: width,
      playerHeight: height,
    });
  };
}
