// @ts-check
import { useState, useEffect } from 'react';

/** @type {unique symbol} */
export const Pending = Symbol('pending');

/**
 * @template T
 * @typedef {Readonly<{ state: 'pending', data: undefined }
 *   | { state: 'rejected', reason: unknown, data: undefined }
 *   | { state: 'success', data: T }
 * >} AsyncState<T>
 */

export const pending = Object.freeze({ state: 'pending', data: undefined });

/**
 * @typedef {object} Options
 * @property {boolean} [Options.skip] if true, promiseCreator will not be executed
 */

/**
 *
 * @template T
 * usePromise is a utility hook for working with promises in components
 *
 * @example
 * const { state, data } = usePromise(() => fetch('...'), []);
 *
 * @param {() => Promise<T>} promiseCreator Function that creates the promise
 * @param {import('react').DependencyList} dependencies changing dependencies will
 * cause promiseCreator function to be called again, and any previous promise results
 * will be discarded
 * @param {Options} [options] Function that creates the promise
 * @returns {AsyncState<T>} An object containing the state of the promise
 * along with resolved data, which will be undefined until the promise is resolved
 */
export function usePromise(promiseCreator, dependencies, options) {
  const { skip = false } = options || {};
  const [asyncState, setAsyncState] = useState(/** @type {AsyncState<T>} */ (pending));

  useEffect(() => {
    let ignore = false;

    setAsyncState(pending);

    if (!skip) {
      promiseCreator().then(
        (result) => {
          if (!ignore) {
            setAsyncState({ state: 'success', data: result });
          }
        },
        (reason) => {
          if (!ignore) {
            setAsyncState({ state: 'rejected', reason, data: undefined });
          }
        },
      );
    }

    return () => {
      ignore = true;
    };
  }, [...dependencies, skip]);

  return asyncState;
}
