import MP4_WITH_SOUND_IN_BASE64 from './autoplayTestVideoBase64.json';

function createTestVideo() {
  const videoElem = document.createElement('video');

  videoElem.setAttribute('style', 'display:none;');
  videoElem.setAttribute('playsinline', 'true');
  videoElem.src = MP4_WITH_SOUND_IN_BASE64;

  return videoElem;
}

function appendVideoToBody(videoElem) {
  // only attach videoElem if video/mp4 is supported
  if (videoElem.canPlayType('video/mp4').length > 0) {
    document.body.appendChild(videoElem);
    return videoElem;
  }
  return null;
}

function destroyVideo(video) {
  if (video) {
    // remove video from the dom
    video.parentNode.removeChild(video);
  }
}

function createAndAppendVideo() {
  return appendVideoToBody(createTestVideo());
}

function testAutoplay(vid, muted = false) {
  vid.muted = muted; // eslint-disable-line no-param-reassign
  const playPromise = vid.play();
  if (playPromise && typeof playPromise.then === 'function') {
    return playPromise.then(
      () => true, // onResolve
      () => false, // onReject
    );
  }
  return Promise.resolve(false);
}

/**
 * @typedef {{
 *  canAutoplayUnmuted: true, canAutoplayMuted: true
 * } | {
 *  canAutoplayUnmuted: false, canAutoplayMuted: boolean
 * }} CapabilityFlags
 */

/**
 * Tests for autoplay capabilities
 * @returns {Promise<CapabilityFlags | undefined>}
 */
function getAutoplayCapabilities() {
  const autoplayCapabilities = { canAutoplayMuted: false, canAutoplayUnmuted: false };
  const vid = createAndAppendVideo();
  if (vid) {
    return testAutoplay(vid)
      .then((playsUnmuted) => {
        if (playsUnmuted) {
          autoplayCapabilities.canAutoplayUnmuted = true;
          autoplayCapabilities.canAutoplayMuted = true;
          destroyVideo(vid);
          return autoplayCapabilities;
        }
        return testAutoplay(vid, true)
          .then((playsMuted) => {
            if (playsMuted) {
              autoplayCapabilities.canAutoplayMuted = true;
            }
            destroyVideo(vid);
            return autoplayCapabilities;
          });
      })
      .catch(() => destroyVideo(vid));
  }
  return Promise.resolve(autoplayCapabilities);
}

export default getAutoplayCapabilities;

// export specifically for unit tests
export const test = {
  createAndAppendVideo,
  destroyVideo,
  testAutoplay,
  getAutoplayCapabilities,
};
