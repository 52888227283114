/* eslint-disable react/prop-types */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/require-default-props */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import {
  videoPlaylist as videoPlaylistPropType,
  video as videoPropType,
} from 'lib/CustomPropTypes';
import { logError } from 'lib/datadog';
import { GridStack } from 'components/GridStack';
import { VideoUnmuteButton } from 'components/VideoUnmuteButton';
// eslint-disable-next-line import/no-unresolved
import { Gamma } from '@nbcnews/gamma';
import { stub as $t } from '@nbcnews/analytics-framework';
import classNames from 'classnames';
import BrowserDetection from 'lib/BrowserDetection';
import { isVideoLive } from 'lib/videoUtils';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { VerticalContext, BreakpointContext } from 'lib/ContextTypes';
import { USE_MPX_VIDEO_PARAMS, ENABLE_RETINA } from 'lib/brandFeatures';
// eslint-disable-next-line import/no-unresolved
import { useConsentStatus } from '@nbcnews/gpp-utilities';
import { JW_PLAYER_LIBRARY_URL } from 'lib/constants';
import { useCombinedCallbacks } from 'lib/Hooks/useCombinedCallbacks';
import MobileApi from 'lib/MobileApi';
import { useVideoControl } from './useVideoControl';
import { Slate } from './Slate';
import { VideoInfoStoryline } from './VideoInfoStoryline';
import { VideoInfo } from './VideoInfo';
import { selectVideoData } from './gammaUtils/selectVideoData';
import { usePlaylistAdapter } from './gammaUtils/usePlaylistAdapter';
import { useAdConfigurator } from './gammaUtils/getAdvertisingConfig';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: { JW_PLAYER_LIBRARY_KEY },
} = getConfig();

/**
 *
 */
const emptyFunction = () => {};

const floatEnabled = {};

/**
 * @typedef {import('@nbcnews/gamma').GammaProps} GammaProps
 * @typedef {GammaProps["playlistItem"]} PlaylistItem
 * @typedef {import('./gammaUtils/usePlaylistAdapter').Playlist} Playlist
 * @typedef {import('./gammaUtils/selectVideoData').VideoData} VideoData
 * @typedef { VideoData & {
 *   associatedVideoPlaylist?: Playlist;
 * }} Video
 */

/**
 * @param {object} props
 * @param {boolean} [props.autoPlay]
 * @param {boolean} [props.isStoryline]
 * @param {boolean} [props.showHeadlineInlineVid]
 * @param {string} [props.inlineHeadlineOverride]
 * @param {boolean} [props.isEmbedded]
 * @param {boolean} [props.useEmbeddedAds]
 * @param {boolean} [props.continuousPlay]
 * @param {boolean} [props.replay]
 * @param {Playlist} [props.playlist]
 * @param {() => void} [props.onAdPlay]
 * @param {(selected: Video, playlistIndex: number, playlist: Playlist) => void} [props.onVideoEnd]
 * @param {() => void} [props.onVideoPlay]
 * @param {boolean} [props.disableSticky]
 * @param {boolean} [props.disableStickyOnMute]
 * @param {boolean} [props.onlyAutoplayMuted]
 * @param {"continuous" | "manual"} [props.nowPlayingInitiator]
 * @param {boolean | undefined} [props.hideVideoTitle]
 * @param {boolean} [props.hidePublishedDate]
 * @param {VideoData} props.video
 */
export function GammaVideoPlayer({
  autoPlay,
  isStoryline,
  showHeadlineInlineVid = true,
  hideVideoTitle,
  inlineHeadlineOverride,
  hidePublishedDate,
  isEmbedded = false,
  useEmbeddedAds = false,
  continuousPlay,
  replay,
  playlist,
  onAdPlay = emptyFunction,
  onVideoEnd = emptyFunction,
  onVideoPlay = emptyFunction,
  disableSticky,
  disableStickyOnMute,
  onlyAutoplayMuted,
  nowPlayingInitiator,
  video,
}) {
  const { data: gppStatus } = useConsentStatus();
  const vertical = React.useContext(VerticalContext);
  const { isM, isL, isXL } = React.useContext(BreakpointContext);
  const useMpxParams = getFeatureConfigForBrand(USE_MPX_VIDEO_PARAMS, vertical);
  const enableRetina = getFeatureConfigForBrand(ENABLE_RETINA, vertical);
  const isLive = isVideoLive(video);

  const {
    title,
    datePublished,
    isExpired,
    duration,
    videoUrl,
    teaseImageUrl,
    teaseImageAltText,
    playlistItem,
    videoId,
    publisherName,
  } = selectVideoData(video, {
    useMpxParams,
    isLive,
    vertical,
    isEmbedded: useEmbeddedAds,
    gppStatus,
    breakpoints: { isM, isL, isXL },
    enableRetina,
  });

  const adConfigurator = useAdConfigurator({
    publisherName,
    isEmbedded: useEmbeddedAds,
    isLiveVideo: isLive,
    gppStatus,
  });

  const [canDisplayMuteBlock, setCanDisplayMuteBlock] = useState(true);
  const analyticsMetadata = useMemo(
    () => ({
      ...video,
      autoPlay,
      continuousPlay,
      nowPlayingInitiator,
    }),
    [video, autoPlay, continuousPlay, nowPlayingInitiator],
  );

  // Temporary fix for a browser issue with safari that if a user mutes via our mute block will lock the player in a nonrecoverable state
  useEffect(() => {
    if (BrowserDetection.isSafari() && BrowserDetection.version() === 16) {
      setCanDisplayMuteBlock(false);
    } else {
      setCanDisplayMuteBlock(true);
    }
  }, []);

  const [hasError, setHasError] = useState(false);

  const onNewPlaylistItem = useCallback(() => {
    setHasError(false);
  }, []);

  const onGammaError = useCallback((error) => {
    const jsErr = new Error('Gamma Error from JWPlayer');
    logError(jsErr, error);
    setHasError(true);
    // eslint-disable-next-line no-console
    console.error('Gamma Error', error);
  }, []);

  // Useful for scanning all JWPlayerEvents. Gamma prop is `onAll`
  // const onGammaEvent = useCallback((event, context) => {
  //   // eslint-disable-next-line no-console
  //   console.log('(g.vp.i153.x) Gamma Event', event, context);
  // }, []);

  const videoControl = useVideoControl({
    autoPlay: (() => {
      /**
       * Don't autoplay if the video is expired, since it will play an ad before
       * JW Player displays the error message
       * @see https://nbcnewsdigital.atlassian.net/browse/NGSP-825
       */
      if (isExpired) return false;
      if (autoPlay && onlyAutoplayMuted) return 'muted';
      return Boolean(autoPlay);
    })(),
    relayEvents: {
      /**
       *
       * @param {boolean} isPaused
       */
      onPauseChange: (/** @type {boolean} */ isPaused) => {
        if (!isPaused) onVideoPlay();
      },
      /**
       *
       */
      onAdImpression() {
        onAdPlay();
      },
      onPlaylistItem: onNewPlaylistItem,
      onError: onGammaError,
      // onAll: onGammaEvent, // useful for devs, see comment above
    },
  });

  const {
    isPlayComplete,
    handleVideoEnd,
    handlePlaylistItem: playlistAdapterItemHandler,
  } = usePlaylistAdapter({
    playlist,
    replay,
    continuousPlay,
    videoId,
    onVideoEnd,
  });

  const handlePlaylistItem = useCombinedCallbacks([
    playlistAdapterItemHandler,
    videoControl.state.onPlaylistItem,
    onNewPlaylistItem,
  ]);

  const disableFloat = disableSticky || (disableStickyOnMute && videoControl.state.isMuted);
  return (
    <GridStack
      data-testid="gamma-video-player"
      className={classNames(`${styles.videoContainer} video-container`, {
        paused: videoControl.state.isPaused,
        started: videoControl.state.hasPlaybackStarted,
      })}
    >
      <Head>
        <link
          key="jw-player-library-preload"
          rel="preload"
          as="script"
          href={JW_PLAYER_LIBRARY_URL}
        />
      </Head>
      <Gamma
        jwPlayerLibraryUrl={JW_PLAYER_LIBRARY_URL}
        jwPlayerLibraryKey={JW_PLAYER_LIBRARY_KEY}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...videoControl.state}
        $t={$t}
        $tMetadata={analyticsMetadata}
        config={{
          skin: {
            name: 'gamma',
          },
          renderCaptionsNatively: false,
        }}
        // Overridden videoControl props
        playlistItem={playlistItem}
        onVideoEnd={handleVideoEnd}
        onPlaylistItem={handlePlaylistItem}
        floating={disableFloat ? undefined : floatEnabled}
        advertising={adConfigurator}
      />
      <Slate
        teaseImageUrl={teaseImageUrl}
        teaseImageAltText={teaseImageAltText}
        isPaused={videoControl.state.isPaused}
        isExpired={isExpired}
        isHidden={hasError}
        hasPlaybackStarted={videoControl.state.hasPlaybackStarted}
        lastVideoIsEnded={isPlayComplete}
        videoHref={videoUrl}
        onPlayClick={() => {
          const result = MobileApi.viewVideo(video.id);
          if (typeof result !== 'string') {
            videoControl.state.onPauseChange(false);
          }
        }}
        onReplayClick={() => videoControl.control.seek(0)}
        title={inlineHeadlineOverride || title}
        showHeadlineInlineVid={showHeadlineInlineVid}
        isStoryline={isStoryline}
      />
      <div className={`info-layer ${styles.infoLayer}`}>
        <VideoUnmuteButton
          show={
            canDisplayMuteBlock
            && videoControl.state.isMuted
            && videoControl.state.hasPlaybackStarted
          }
          onClickUnmute={() => videoControl.state.onMuteChange(false)}
        />
        {isStoryline
          ? (
            <VideoInfoStoryline
              title={inlineHeadlineOverride || title}
              hideVideoTitle={hideVideoTitle}
              date={hidePublishedDate ? undefined : datePublished}
              duration={duration}
              href={videoUrl}
              isEmbedded={isEmbedded}
              forceShow={videoControl.state.isPaused || isPlayComplete}
              isStoryline={isStoryline}
              videoState={videoControl.state}
            />
          )
          : (
            <VideoInfo
              title={title}
              hideVideoTitle={hideVideoTitle}
              date={hidePublishedDate ? undefined : datePublished}
              duration={duration}
              href={videoUrl}
              isEmbedded={isEmbedded}
              forceShow={videoControl.state.isPaused || isPlayComplete}
            />
          )}
      </div>
    </GridStack>
  );
}

GammaVideoPlayer.propTypes = {
  // // Page Values
  // pageView: PropTypes.string.isRequired,
  video: videoPropType.isRequired,
  // /* eslint-disable react/no-unused-prop-types */
  autoPlay: PropTypes.bool,
  // manualPlay: PropTypes.bool,
  onAdPlay: PropTypes.func,
  onAdEnd: PropTypes.func,
  onVideoPlay: PropTypes.func,
  onVideoPause: PropTypes.func,
  onVideoMute: PropTypes.func,
  onVideoUnmute: PropTypes.func,
  // hasClickedToPlay: PropTypes.bool,
  // /* eslint-enable react/no-unused-prop-types */
  continuousPlay: PropTypes.bool,
  replay: PropTypes.bool,
  playlist: videoPlaylistPropType,
  onVideoEnd: PropTypes.func,
  // // onRef: PropTypes.func,
  // isChromeless: PropTypes.bool,
  // playButtonStyle: PropTypes.string,
  // centerPlayButtonAtMobile: PropTypes.bool,
  // isEmbedded: PropTypes.bool,
  useEmbeddedAds: PropTypes.bool,
  // isRailLayout: PropTypes.bool,
  // isLiveVideoEmbed: PropTypes.bool,
  // shouldStickWhilePaused: PropTypes.bool,
  // shouldPauseWhenMutedAndOutOfView: PropTypes.bool,
  disableSticky: PropTypes.bool,
  disableStickyOnMute: PropTypes.bool,
  // mute: PropTypes.bool,
  // adPlayButtonPosition: PropTypes.oneOf(['default', 'bottomLeft']),
  // loadingLazy: PropTypes.bool,
  // pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
  // pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),

  // // Autoplay
  // autoplayCapabilities: autoplayCapabilitiesPropType,
  // // autoplayTestGroup: PropTypes.string,
  // // isTestingAutoplayCapabilities: PropTypes.bool,
  onlyAutoplayMuted: PropTypes.bool,
};
