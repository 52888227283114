// player does handle click events from keyboard, linter doesn't recognize this
/* eslint-disable react/prop-types */
/* eslint-disable jsdoc/check-property-names */
import React from 'react';
import classNames from 'classnames';
import { GridStack } from 'components/GridStack';
import styles from './storyline.styles.module.scss';
import { TeaseImage } from './TeaseImage';
/**
 * @typedef {object} ImageProps
 * @property {string} ImageProps.src background image source url
 * @property {number | undefined} [ImageProps.height] Image height
 * @property {number | undefined} [ImageProps.width] Image width
 * @property {string} ImageProps.alt Image altText
 * @property {boolean} [ImageProps.disableLazyLoad] false by default.  Set to `true` if lazy loading
 * is interfering with largest contentful paint metric
 */

/**
 * @typedef {object} SlateButtonProps
 * @property {() => void} SlateButtonProps.onClick Fires when a user clicks the slate
 * @property {string} [SlateButtonProps.href] If set, slate will additionally function as a link
 * @property {string} SlateButtonProps.teaseImageUrl the image to use as the background
 * @property {string} SlateButtonProps.teaseImageAltText the alt text for the background image
 * @property {string} SlateButtonProps.title the title text for inline video
 * @property {boolean} SlateButtonProps.showHeadlineInlineVid the toggle for title text for inline video
 */

/**
 * @param {() => void} onClick
 */
export function createButtonEventsStoryline(onClick) {
  return {
    /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
    handleClick(e) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    /** @type {import('react').KeyboardEventHandler<HTMLDivElement>} */
    handleKeyDown(e) {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }
    },
  };
}


/**
 * Slate with a play button
 * @param {SlateButtonProps} props
 */
export function PlayButtonSlateStoryline({
  onClick, teaseImageUrl, teaseImageAltText, title, showHeadlineInlineVid,
}) {
  const { handleClick, handleKeyDown } = createButtonEventsStoryline(onClick);
  const hasGradient = showHeadlineInlineVid ? styles.hasGradient : '';
  return (
    <GridStack className={classNames('videoSlate', styles.videoSlate)}>
      <div
        role="button"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label="Play"
        tabIndex={0}
      >
        <TeaseImage src={teaseImageUrl} altText={teaseImageAltText} />
        <div className={classNames(styles.inlinePlay, hasGradient)}>
          <div className={classNames(styles.buttonHeadlineContainer)}>
            <PlayIcon />
            {showHeadlineInlineVid && <div className={styles.inlineTitle}>{title}</div>}
          </div>
        </div>
      </div>
    </GridStack>
  );
}

/**
 *
 */
function PlayIcon() {
  return (
    <div
      className={styles.playButtonOuter}
      data-testid="video-player__play-button__outer"
    >
      <div
        className={styles.playButtonContainer}
      >
        <div className={styles.play}>
          <svg
            className="play--icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            preserveAspectRatio="xMidYMid"
            style={{ maxWidth: '30px' }}
          >
            <path d="M0 0v15l15-7.41z" fillRule="evenodd" />
          </svg>
        </div>
      </div>
    </div>
  );
}
