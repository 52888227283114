import { useCallback } from 'react';

/**
 * Utility hook to combine multiple callbacks into a single callback
 *
 * @template {any[]} T
 * @param {Array<((...args: T) => void) | undefined>} callbacks
 * @returns {(..args: T) => void}
 */
export function useCombinedCallbacks(callbacks) {
  return useCallback(
    (...args) => callbacks.map((callback) => ({ success: callback?.(...args) })),
    callbacks,
  );
}
