/**
 * @summary Formats EMP asset types to be playable by NDP
 * @description NDP code behaves differently based on the values of the video
 * asset object. In order to make it play the new EMP video types, we need to
 * fake some values from the older `akamaiMp4` asset type. Specifically we need
 * to set the `assetType` and `format` props, as well as include the
 * `formats=M3U` query param.
 * @param {VideoAsset} asset The asset to transform
 * @returns {VideoAsset}
 */
export function formatEMPAsset(asset) {
  let parsedPublicUrl;
  try {
    parsedPublicUrl = new URL(asset.publicUrl);
    parsedPublicUrl.searchParams.set('formats', 'M3U');
  } catch (e) {
    // `new URL` will throw if the arg isn't a valid URL. no op
  }
  return {
    ...asset,
    assetType: 'akamaiMp4',
    format: 'MPEG4',
    publicUrl: parsedPublicUrl?.toString() || asset.publicUrl,
  };
}

/**
 * Filter for only video asset(s) that are both of type EMP and format M3U;
 * otherwise return the original video assets
 */
export const selectEMPAssetType = (videoAssets) => {
  const EMPVideoAssets = videoAssets.filter((videoAsset) => {
    const { assetType, format } = videoAsset;
    return assetType === 'EMP' && format === 'M3U';
  });

  if (EMPVideoAssets.length) {
    return EMPVideoAssets.map(formatEMPAsset);
  }

  return videoAssets;
};
