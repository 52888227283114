import getVideoAutoplayCapabilities from 'lib/getVideoAutoplayCapabilities';
import { usePromise } from 'lib/Hooks/usePromise';

/**
 * @typedef {import('lib/getVideoAutoplayCapabilities').CapabilityFlags} CapabilityFlags
 */

/**
 * Determines browser capabilities for autoplay
 * @param {boolean} testRequired if false, skip testing
 * @returns {CapabilityFlags}
 */
export function useAutoplayCapabilities(testRequired) {
  /** @type {CapabilityFlags} */
  const defaultValue = { canAutoplayUnmuted: false, canAutoplayMuted: false };

  const { data } = usePromise(getVideoAutoplayCapabilities, [], { skip: !testRequired });

  return data ?? defaultValue;
}
