/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import IconLoader from 'components/IconLoader';
import { useVertical } from 'lib/Hooks/useVertical';

import styles from './styles.module.scss';

/**
 * Slate for when video is loading
 */
export function LoadingSlate() {
  const { isToday, isMsnbc, isNews } = useVertical();

  let loaderBrand = 'default';
  if (isToday) {
    loaderBrand = 'today';
  } else if (isMsnbc || isNews) {
    loaderBrand = 'news';
  }

  return (
    <div className={classNames(styles.spinnerLoader)} aria-label="Loading">
      <IconLoader
        className="relative z-2"
        brand={loaderBrand}
        animateIn
        animateOut
      />
    </div>
  );
}
