import { isBrowser } from 'lib/BrowserDetection';

function getReferrer() {
  return isBrowser() && document && document.referrer;
}

export function isOnNewsDigitalSite() {
  const referrer = getReferrer();
  const newsDigitalUrlRegex = /:\/\/[^.]+\.(nbcnews|today|msnbc)\.com\//;
  const hasNewsDigitalReferrer = !referrer || referrer.match(newsDigitalUrlRegex);

  return hasNewsDigitalReferrer;
}

export function isOnTelemundoSite() {
  const referrer = getReferrer();
  return !referrer || !!referrer.match(/:\/\/[^.]+\.telemundo\.com\//);
}
