import React from 'react';
import { Trans } from 'react-i18next';

export function ExpiredSlate() {
  return (
    <div
      className="expiredOverlay items-center df absolute w-100 h-100 z-2 f7 f8-m"
      data-test="video-player__expired-overlay"
    >
      <div className="expiredText founders-cond tc w-100 gray-60">
        <Trans>We apologize, this video has expired.</Trans>
      </div>
    </div>
  );
}
