/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getFormattedDateString, formatDuration } from 'lib/DateTime';
import SocialShareInline from 'components/Social/ShareInline';
import { useVertical } from 'lib/Hooks/useVertical';
import Link from 'components/Link';
import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.href
 * @param {string} [props.date]
 * @param {string} props.duration
 * @param {boolean} [props.forceShow]
 * @param {boolean} [props.isEmbedded]
 * @param {boolean} [props.hideVideoTitle]
 * @param {boolean} [props.isStoryline]
 * @param {object} [props.videoState]
 */
export function VideoInfoStoryline({
  title,
  href,
  date,
  duration,
  forceShow,
  isEmbedded,
  hideVideoTitle,
  isStoryline,
  videoState,
}) {
  const { vertical } = useVertical();
  return (
    <div
      className={classNames(styles.background, { [styles.show]: forceShow, [styles.multistoryline]: isStoryline, [styles.notStarted]: !videoState?.hasPlaybackStarted })}
    >
      <SocialShareInline
        content={{
          headline: { actual: title },
          shortUrl: href,
        }}
        propStyles={{
          share: `${styles.share} share`,
          shareClose: 'share-close',
          shareDot: 'share-dot',
          shareExpanded: 'share-expanded',
          shareExpander: 'share-expander',
          shareIcon: 'share-icon',
          shareList: 'share-list',
        }}
        vertical={vertical}
      />
      { !hideVideoTitle ? (
        <div className={styles.overlayText}>
          <div data-testid="gamma-vid-title" className={classNames(styles.title)}>
            {/* If in an embedded context (e.g. iframe) we want the link to load in the parent context */}
            <Link className={styles.link} href={href} target={isEmbedded ? '_parent' : undefined}>{title}</Link>
          </div>
          {
            !isStoryline && (
              <div className={styles.detail}>
                {date ? <span>{getFormattedDateString(date)}</span> : null}
                <span>{formatDuration(duration)}</span>
              </div>
            )
          }
        </div>
      ) : null }
    </div>
  );
}

VideoInfoStoryline.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  date: PropTypes.string,
  duration: PropTypes.string,
  forceShow: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  hideVideoTitle: PropTypes.bool,
  isStoryline: PropTypes.bool,
  videoState: PropTypes.object,
};
