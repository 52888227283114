import { isTelemundoVertical } from 'lib/vertical';
import { setFreeWheelWVideoParamCookie, getPrivacyCookie } from 'lib/videoParamsCookie';
import { isVideoLive } from 'lib/videoUtils';

const DESKTOP_SITE_SECTION = {
  msnbc: 'msnbc_allos_web_ondemand',
  today: 'today_canonical_video',
  noticias: 'telemundo_noticias_vod',
  deportes: 'telemundo_deportes_vod',
  localstationdeportes: 'telemundo_desktop_computer_web_ondemand_deportes_local_embed',
  entretenimiento: 'telemundo_entretenimiento_vod',
  shows: 'telemundo_shows_vod',
  telemundo: 'telemundo_vod',
};

const MOBILE_SITE_SECTION = {
  msnbc: 'msnbc_mobile_allos_web_ondemand',
  today: 'today_canonical_video_mobile',
  noticias: 'telemundo_noticias_vod_hh',
  deportes: 'telemundo_deportes_vod_hh',
  localstationdeportes: 'telemundo_mobile_allos_web_ondemand_deportes_local_embed',
  entretenimiento: 'telemundo_entretenimiento_vod_hh',
  shows: 'telemundo_shows_vod_hh',
  telemundo: 'telemundo_vod_hh',
};

const DESKTOP_SITE_LIVE_SECTION = {
  msnbc: 'msnbc_allos_web_ondemand',
  today: 'today_canonical_video',
  noticias: 'telemundo_noticias_live',
  deportes: 'telemundo_deportes_live',
  localstationdeportes: 'telemundo_desktop_computer_web_ondemand_deportes_local_embed',
  entretenimiento: 'telemundo_entretenimiento_live',
  shows: 'telemundo_shows_live',
  telemundo: 'telemundo_live',
};

const MOBILE_SITE_LIVE_SECTION = {
  msnbc: 'msnbc_mobile_allos_web_ondemand',
  today: 'today_canonical_video_mobile',
  noticias: 'telemundo_noticias_live_hh',
  deportes: 'telemundo_deportes_live_hh',
  localstationdeportes: 'telemundo_mobile_allos_web_ondemand_deportes_local_embed',
  entretenimiento: 'telemundo_entretenimiento_live_hh',
  shows: 'telemundo_shows_live_hh',
  telemundo: 'telemundo_live_hh',
};

const VIDEO_ASSET_FALLBACK_ID = {
  msnbc: 137705082,
  today: 137705821,
  noticias: 137391198,
  deportes: 137391198,
  localstationdeportes: 137391198,
  entretenimiento: 137391198,
  shows: 137391198,
  telemundo: 137391198,
};

const DESKTOP_SITE_SECTION_FALLBACK_ID = {
  noticias: 7005949,
  deportes: 7005949,
  localstationdeportes: 7005949,
  entretenimiento: 7005949,
  shows: 7005949,
  telemundo: 7005949,
};

const MOBILE_SITE_SECTION_FALLBACK_ID = {
  noticias: 7003959,
  deportes: 7003959,
  localstationdeportes: 7003959,
  entretenimiento: 7005949,
  shows: 7003959,
  telemundo: 7003959,
};

function getVideoAssetFallbackID(vertical) {
  return VIDEO_ASSET_FALLBACK_ID[vertical] || 137704898;
}

function getSiteSectionFallbackID(isMobile, vertical) {
  if (isMobile) {
    return MOBILE_SITE_SECTION_FALLBACK_ID[vertical] || 7003948;
  }

  return DESKTOP_SITE_SECTION_FALLBACK_ID[vertical] || 7006049;
}

function getDesktopSiteSection(vertical, video) {
  return (isVideoLive(video)
    ? DESKTOP_SITE_LIVE_SECTION[vertical]
    : DESKTOP_SITE_SECTION[vertical])
 || 'nbcnews_allos_web_ondemand';
}

function getMobileSiteSection(vertical, video) {
  return (isVideoLive(video)
    ? MOBILE_SITE_LIVE_SECTION[vertical]
    : MOBILE_SITE_SECTION[vertical])
 || 'nbcnews_mobile_allos_web_ondemand';
}

function isNotOnNewsDigitalSites(referrer) {
  return referrer && !referrer.match(/:\/\/[^.]+\.(nbcnews|today|msnbc)\.com\//);
}

function isNotOnTelemundo(referrer) {
  return referrer && !referrer.match(/:\/\/[^.]+\.telemundo\.com\//);
}

function getSiteSection({
  article,
  video,
  vertical,
  pageView,
  isEmbedded,
  referrer,
  isMobile,
}) {
  // Alternate siteSection value for today articles
  if (vertical === 'today' && pageView === 'article') {
    const primarySection = article?.taxonomy?.primarySection?.slug;
    const primaryTopic = article?.taxonomy?.primaryTopic?.slug;
    const siteSectionBuilder = ['today'];

    if (primarySection) {
      siteSectionBuilder.push(primarySection);
    }

    if (primaryTopic) {
      siteSectionBuilder.push(primaryTopic);
    }

    if (isMobile) {
      siteSectionBuilder.push('mobile');
    }
    // Append key if embedded
    if (isEmbedded) {
      siteSectionBuilder.push('embed');
    }

    return siteSectionBuilder.join('_');
  }

  let siteSection = isMobile
    ? getMobileSiteSection(vertical, video)
    : getDesktopSiteSection(vertical, video);

  const isTelemundoPage = isTelemundoVertical(vertical);
  const isNewsDigitalPage = !isTelemundoPage;
  const offSiteTelemundo = isTelemundoPage && isNotOnTelemundo(referrer);
  const offSiteNewsDigital = isNewsDigitalPage && isNotOnNewsDigitalSites(referrer);

  if (isEmbedded && (offSiteTelemundo || offSiteNewsDigital)) {
    siteSection += '_embed';
  }

  return siteSection;
}

function getVideoAdConfig({
  article,
  video,
  video: {
    sentiment: videoSentiment,
  } = {},
  continuousPlay = false,
  autoPlay = false,
  vprn,
  vertical,
  isEmbedded,
  pageView,
  referrer,
  isMobile = false,
} = {}) {
  const label = article?.taxonomy?.primaryLabel?.slug ?? '';
  const sect = article?.taxonomy?.primarySection?.slug ?? '';
  const articleSeriesTaxonomies = article?.taxonomy?.series ?? [];

  const series = articleSeriesTaxonomies
    .map((taxonomy) => taxonomy.slug)
    .join('|');

  const videoAdConfig = {
    continuousPlay,
    useCustomControls: true,
    autoPlay,
    // properties in `siteData` will be passed through to Freewheel Ad calls
    siteData: {
      // wawjr3d [11/14/2019] siteSection is csid
      siteSection: getSiteSection({
        article,
        video,
        vertical,
        pageView,
        isEmbedded,
        referrer,
        isMobile,
      }),
      ssl: 'true',
      cont: article ? 'inline' : 'canonical',
      bento: 'true',
      label,
      series,
      sect,
      pvrn: Math.floor((Math.random() * 1000000)).toString(),
      vprn,
      _fw_vcid2: setFreeWheelWVideoParamCookie(),
      _fw_us_privacy: getPrivacyCookie(), // if no cookie, default to value no option to opt out
    },
    // new properties added here will not be passed through to Freewheel Ad calls
    freewheel: {
      playerProfile: 'nbcnews_{runtime}_live_https',
      network: 169843,
      url: 'https://29773.v.fwmrm.net/ad/p/1',
      siteSection: 'NBCNEWS_fallback_site_section',
      siteSectionFallbackID: getSiteSectionFallbackID(isMobile, vertical),
      videoAssetFallbackID: getVideoAssetFallbackID(vertical),
      // eslint-disable-next-line max-len
      adManagerUrl: 'https://media1.s-nbcnews.com/i/videoassets/pdk/vendor/AdManager-6.5.0.r1.{ext}',
      // timeout counter should default to 3000 milliseconds
      requestTimeout: 3000,
    },
  };

  if (videoSentiment) {
    videoAdConfig.siteData['custom[context]'] = videoSentiment;
  }

  return videoAdConfig;
}

export default getVideoAdConfig;
