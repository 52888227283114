/* eslint-disable no-use-before-define */
import { useCallback, useState } from 'react';

/** @typedef {{
 *   videos: {
 *     id: string,
 *   }[]
 * }} Playlist

/**
 * Adapts Gamma's video events into playlist-based events that ramen video player
 * pages use.
 *
 * @param {object} props
 * @param {Playlist} [props.playlist]
 * @param {boolean} [props.replay]
 * @param {boolean} [props.continuousPlay]
 * @param {string} props.videoId
 * @param {function} props.onVideoEnd
 */
export function usePlaylistAdapter({
  playlist, replay, continuousPlay, videoId, onVideoEnd,
}) {
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleVideoEnd = useCallback(() => {
    if (playlist && (replay || continuousPlay)) {
      const playlistIndex = playlist.videos.findIndex((v) => v.id === videoId) || 0;
      const nextIndex = playlistIndex >= playlist.videos.length ? 0 : playlistIndex + 1;
      const nextVideo = playlist.videos[nextIndex];
      if (nextVideo) {
        onVideoEnd(
          { ...nextVideo, associatedVideoPlaylist: playlist },
          nextIndex,
          playlist,
          'continuous',
        );
      }
    }
    setIsAtEnd(!continuousPlay && isLastVideo(videoId, playlist));
  }, [continuousPlay, onVideoEnd, playlist, replay, videoId]);

  return {
    isPlayComplete: isAtEnd,
    handleVideoEnd,
    handlePlaylistItem() {
      setIsAtEnd(false);
    },
  };
}

/**
 * @param {string} videoId
 * @param {Playlist | undefined} playlist
 */
export function isLastVideo(videoId, playlist) {
  if (!playlist) {
    return true;
  }
  return playlist?.videos.findIndex((v) => v.id === videoId) === playlist.videos.length - 1;
}
