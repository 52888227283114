import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { formatDuration } from 'lib/DateTime';
import { getElementClickPosition } from 'lib/elementUtils';

import styles from './styles.module.scss';

class ProgressBar extends React.Component {
  static propTypes = {
    currentPosition: PropTypes.number,
    totalDuration: PropTypes.number,
    onScrub: PropTypes.func,
    isLive: PropTypes.bool,
  };

  static defaultProps = {
    currentPosition: 0,
    totalDuration: 0,
    onScrub: Function.prototype,
    isLive: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltipPosition: null,
    };
  }

  onClick = (e) => {
    const { onScrub } = this.props;
    const tooltipPosition = getElementClickPosition(e);

    onScrub(tooltipPosition.percentage);
  }

  mouseEnter = (e) => {
    const tooltipPosition = getElementClickPosition(e);

    this.setState({
      tooltipPosition,
    });
  }

  mouseLeave = () => {
    this.setState({
      tooltipPosition: null,
    });
  }

  touchStart = (e) => {
    const tooltipPosition = getElementClickPosition(e.targetTouches.item(0));

    this.setState({
      tooltipPosition,
    });
  }

  touchEnd = (e) => {
    e.preventDefault();
    const { onScrub } = this.props;
    const tooltipPosition = getElementClickPosition(e.changedTouches.item(0));

    onScrub(tooltipPosition.percentage);

    this.setState({
      tooltipPosition: null,
    });
  }

  touchMove = (e) => {
    const tooltipPosition = getElementClickPosition(e.changedTouches.item(0));

    this.setState({
      tooltipPosition,
    });
  }

  tooltip() {
    const { totalDuration } = this.props;
    const { tooltipPosition } = this.state;

    const time = formatDuration(tooltipPosition.percentage * totalDuration);

    const style = {
      left: `${tooltipPosition.left + 20}px`,
    };

    return (
      <div className={styles.tooltip} style={style}>{time}</div>
    );
  }

  render() {
    const { currentPosition, totalDuration, isLive } = this.props;
    const { tooltipPosition } = this.state;

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <div
        onMouseEnter={isLive ? Function.prototype : this.mouseEnter}
        onMouseLeave={isLive ? Function.prototype : this.mouseLeave}
        onMouseMove={isLive ? Function.prototype : this.mouseEnter}
        onClick={isLive ? Function.prototype : this.onClick}
        onTouchStart={isLive ? Function.prototype : this.touchStart}
        onTouchEnd={isLive ? Function.prototype : this.touchEnd}
        onTouchMove={isLive ? Function.prototype : this.touchMove}
      >
        <progress
          value={!isLive ? currentPosition : 100}
          max={!isLive ? totalDuration : 100}
          className={classNames(styles.progress, { [styles.isLive]: isLive })}
        />

        {!!tooltipPosition && !isLive && this.tooltip()}
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }
}

export default ProgressBar;
