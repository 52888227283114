/*
  *****WARNING*****

  User agent sniffing is an anti-pattern, you may want to explore other approaches
  before using code in this lib
  https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

  *****WARNING*****
*/

const MobileDetection = {
  hasUserAgent() {
    if (typeof navigator !== 'undefined') {
      return navigator.userAgent || ' ';
    }
    return false;
  },

  Android() {
    return this.hasUserAgent()
      && navigator.userAgent.match(/Android/i);
  },

  BlackBerry() {
    return this.hasUserAgent()
      && navigator.userAgent.match(/BlackBerry/i);
  },

  iOS() {
    // Last line is needed for mobile detection to work on iPadOS13+
    // since its userAgent returns Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko)
    return this.hasUserAgent()
      && (navigator.userAgent.match(/iPhone|iPad|iPod/i)
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document));
  },

  Opera() {
    return this.hasUserAgent()
      && navigator.userAgent.match(/Opera Mini/i);
  },

  Windows() {
    return this.hasUserAgent()
      && navigator.userAgent.match(/IEMobile/i);
  },

  any() {
    return !!(
      MobileDetection.Android()
      || MobileDetection.BlackBerry()
      || MobileDetection.iOS()
      || MobileDetection.Opera()
      || MobileDetection.Windows()
    );
  },
};

export default MobileDetection;
