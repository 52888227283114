import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const PlayerViewport = memo(({ inputRef, id, viewportClickHandler }) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  <div data-testid="player-viewport" ref={inputRef} id={id} onClick={viewportClickHandler} />
));

PlayerViewport.propTypes = {
  inputRef: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  viewportClickHandler: PropTypes.func,
};

PlayerViewport.defaultProps = {
  viewportClickHandler: null,
};

