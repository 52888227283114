/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  autoplayCapabilities as autoplayCapabilitiesPropType,
  article as articlePropType,
  video as videoPropType,
  videoPlaylist as videoPlaylistPropType,
} from 'lib/CustomPropTypes';
import { isVideoLive } from 'lib/videoUtils';
import { GammaVideoPlayer } from 'components/Gamma/VideoPlayer';
import NdpVideoPlayer from 'components/NdpVideoPlayer';
import { VerticalContext, useFeatureFlagContext } from 'lib/ContextTypes';
import { useVertical } from 'lib/Hooks/useVertical';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { logError } from 'lib/datadog';
import { useIsGammaEnabled } from 'components/Gamma/GammaSwitch';
// we're using lib/vertical functions for non-deprecated reasons (i.e. we really do want the vertical)
import { isTelemundoVertical } from 'lib/vertical';
import { isOnNewsDigitalSite, isOnTelemundoSite } from 'components/Gamma/VideoPlayer/gammaUtils/siteDetector';

export function VodPlayerSwitch(props) {
  const { vertical: contextVertical } = useVertical();
  const {
    vertical: propsVertical,
    playlist,
    isEmbedded,
    video,
    hideVideoTitle,
    isStoryline,
    showHeadlineInlineVid,
    inlineHeadlineOverride,
    ...playerProps
  } = props;

  const vertical = propsVertical || contextVertical;

  const isTelemundoVert = isTelemundoVertical(vertical);
  const isNewsDigitalVert = !isTelemundoVert;

  const offSiteTelemundo = isTelemundoVert && !isOnTelemundoSite();
  const offSiteNewsDigital = isNewsDigitalVert && !isOnNewsDigitalSite();

  const useEmbeddedAds = isEmbedded && (offSiteTelemundo || offSiteNewsDigital);

  const isGamma = useIsGammaEnabled();

  const { 'use-gamma-on-telemundo-live-videos': useGammaOnLiveVideo } = useFeatureFlagContext();
  const isLive = isVideoLive(video);

  if ((isGamma) || (isTelemundoVert && isLive && useGammaOnLiveVideo)) {
    // Vertical context is overridden if a `vertical` prop is provided.  This allows the player to
    // follow its "original" look and feel even if the player is being viewed under another domain.
    // This happens with AMP videos for example.
    return (
      <VerticalContext.Provider value={vertical}>
        <ErrorBoundary errorLogger={logError}>
          <GammaVideoPlayer
            {...playerProps}
            hideVideoTitle={hideVideoTitle}
            video={video}
            isEmbedded={isEmbedded}
            useEmbeddedAds={useEmbeddedAds}
            isStoryline={isStoryline}
            showHeadlineInlineVid={showHeadlineInlineVid}
            inlineHeadlineOverride={inlineHeadlineOverride}
            // TODO: Below are changes to VideoPlayer props that we want to adopt. When removing the
            // feature toggle, be sure to change all VideoPlayer call sites to pass props as below.
            // VideoPlayer component always uses the first playlist anyway, and the prop name is
            // singular.
            playlist={playlist?.[0]}
          />
        </ErrorBoundary>
      </VerticalContext.Provider>
    );
  }

  return <NdpVideoPlayer {...props} />;
}

// eslint-disable-next-line import/no-default-export
export default VodPlayerSwitch;

VodPlayerSwitch.propTypes = {
  // Page Values
  pageView: PropTypes.string,
  vertical: PropTypes.string,
  article: articlePropType,
  video: videoPropType.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  autoPlay: PropTypes.bool,
  manualPlay: PropTypes.bool,
  onAdPlay: PropTypes.func,
  onAdEnd: PropTypes.func,
  onVideoPlay: PropTypes.func,
  onVideoPause: PropTypes.func,
  onVideoMute: PropTypes.func,
  onVideoUnmute: PropTypes.func,
  hasClickedToPlay: PropTypes.bool,
  /* eslint-enable react/no-unused-prop-types */
  continuousPlay: PropTypes.bool,
  replay: PropTypes.bool,
  playlist: PropTypes.arrayOf(videoPlaylistPropType),
  onVideoEnd: PropTypes.func,
  onRef: PropTypes.func,
  isChromeless: PropTypes.bool,
  playButtonStyle: PropTypes.string,
  centerPlayButtonAtMobile: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  isRailLayout: PropTypes.bool,
  isLiveVideoEmbed: PropTypes.bool,
  shouldStickWhilePaused: PropTypes.bool,
  shouldPauseWhenMutedAndOutOfView: PropTypes.bool,
  disableSticky: PropTypes.bool,
  disableStickyOnMute: PropTypes.bool,
  mute: PropTypes.bool,
  adPlayButtonPosition: PropTypes.oneOf(['default', 'bottomLeft']),
  loadingLazy: PropTypes.bool,
  pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
  pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),
  hideVideoTitle: PropTypes.bool,
  isStoryline: PropTypes.bool,
  // Autoplay
  autoplayCapabilities: autoplayCapabilitiesPropType,
  autoplayTestGroup: PropTypes.string,
  isTestingAutoplayCapabilities: PropTypes.bool,
  onlyAutoplayMuted: PropTypes.bool,
  showHeadlineInlineVid: PropTypes.bool,
  inlineHeadlineOverride: PropTypes.string,
};

VodPlayerSwitch.defaultProps = {
  article: null,
  autoPlay: false,
  manualPlay: false,
  continuousPlay: false,
  replay: false,
  playlist: [],
  onAdPlay: Function.prototype,
  onAdEnd: Function.prototype,
  onVideoPlay: Function.prototype,
  onVideoPause: Function.prototype,
  onVideoEnd: Function.prototype,
  onVideoMute: Function.prototype,
  onVideoUnmute: Function.prototype,
  hasClickedToPlay: false,
  onRef: Function.prototype,
  isChromeless: false,
  isRailLayout: false,
  playButtonStyle: null,
  centerPlayButtonAtMobile: false,
  isEmbedded: false,
  isLiveVideoEmbed: false,
  shouldStickWhilePaused: false,
  shouldPauseWhenMutedAndOutOfView: false,
  disableSticky: false,
  disableStickyOnMute: false,
  mute: false,
  autoplayCapabilities: null,
  autoplayTestGroup: null,
  isTestingAutoplayCapabilities: false,
  onlyAutoplayMuted: true,
  adPlayButtonPosition: 'default',
  loadingLazy: null,
  pipAlignDesktop: 'bottom',
  pipAlignMobile: 'bottom',
  vertical: '',
  pageView: '',
  hideVideoTitle: false,
  isStoryline: false,
  showHeadlineInlineVid: false,
  inlineHeadlineOverride: '',
};
