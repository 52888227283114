/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getFormattedDateString, formatDuration } from 'lib/DateTime';
import SocialShareInline from 'components/Social/ShareInline';
import { useVertical } from 'lib/Hooks/useVertical';
import Link from 'components/Link';
import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.href
 * @param {string} [props.date]
 * @param {string} props.duration
 * @param {boolean} [props.forceShow]
 * @param {boolean} [props.isEmbedded]
 * @param {boolean} [props.hideVideoTitle]
 */
export function VideoInfo({
  title,
  href,
  date,
  duration,
  forceShow,
  isEmbedded,
  hideVideoTitle,
}) {
  const { vertical } = useVertical();

  return (
    <div className={classNames(styles.background, { [styles.show]: forceShow })}>
      <SocialShareInline
        content={{
          headline: { actual: title },
          shortUrl: href,
        }}
        propStyles={{
          share: `${styles.share} share`,
          shareClose: 'share-close',
          shareDot: 'share-dot',
          shareExpanded: 'share-expanded',
          shareExpander: 'share-expander',
          shareIcon: 'share-icon',
          shareList: 'share-list',
        }}
        vertical={vertical}
      />
      { !hideVideoTitle ? (
        <div data-testid="overlay-text" className={styles.overlayText}>
          <div className={classNames(styles.title)}>
            {/* If in an embedded context (e.g. iframe) we want the link to load in the parent context */}
            <Link href={href} target={isEmbedded ? '_parent' : undefined}>{title}</Link>
          </div>
          <div className={styles.detail}>
            {date ? <span>{getFormattedDateString(date)}</span> : null}
            <span>{formatDuration(duration)}</span>
          </div>
        </div>
      ) : null }
    </div>
  );
}

VideoInfo.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  date: PropTypes.string,
  duration: PropTypes.string,
  forceShow: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  hideVideoTitle: PropTypes.bool,
};
