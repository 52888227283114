import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import classNames from 'classnames';
import getConfig from 'next/config';
import get from 'lodash.get';
import Head from 'next/head';

import Breakpoints from 'lib/Breakpoints';
import BrowserDetection from 'lib/BrowserDetection';
import BTE from 'lib/BTE';
import { buildVideoAsset } from 'lib/buildVideoAsset';
import { ndpOrigin, chooseNDPScript } from 'lib/chooseNDPScript';
import {
  autoplayCapabilities as autoplayCapabilitiesPropType,
  article as articlePropType,
  video as videoPropType,
  videoPlaylist as videoPlaylistPropType,
} from 'lib/CustomPropTypes';
import { formatDuration } from 'lib/DateTime';
import { getUpdatedQueryParamStatus } from 'lib/getFeatureStatus';
import getNavbarHeight from 'lib/getNavbarHeight';
import getVideoAdConfig from 'lib/getVideoAdConfig';
import InactivityMonitor from 'lib/InactivityMonitor';
import loadScript from 'lib/loadScript';
import MobileApi from 'lib/MobileApi';
import MobileDetection from 'lib/MobileDetection';
import {
  isVideoLive,
  AUTOPLAY_TEST_GROUPS,
  AUTOPLAY_TEST_AD_KVS,
  VIDEO_RESERVE_PIP_GLOBAL_EVENT,
  VIDEO_PLAYING_GLOBAL_EVENT,
  VIDEO_PLAYING_EVENT_ATTENUATION,
  gainToDBFS,
  dBFSToGain,
} from 'lib/videoUtils';

import IconLoader from 'components/IconLoader';
import { VideoUnmuteButton } from 'components/VideoUnmuteButton';

import { isTelemundoVertical } from 'lib/vertical';
import { stub as $t } from '@nbcnews/analytics-framework';
import { VideoPlayerContext } from 'lib/ContextTypes/videoPlayer';
import { PlayerViewport } from './PlayerViewport';
import ProgressBar from './ProgressBar';
import VideoSlate from './VideoSlate';

import styles from './styles.module.scss';


const {
  publicRuntimeConfig: {
    ENABLE_AUTOPLAY_TEST,
  },
} = getConfig();

const block = 'videoPlayer';

const VIDEO_LOAD_ICON_TRACKING_EVENT = 'ramen_video_load_icon';
$t('register', VIDEO_LOAD_ICON_TRACKING_EVENT); // eslint-disable-line no-undef

const createNDPAsset = (video) => new window.$ndp.HLSPriorityMediaAsset(buildVideoAsset(video));

const loudnessMinimum = -50;

const button = (type, onClick = Function.prototype, className = '') => (
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  <span className={`${block}__${type} ${className} icon icon-${type}`} onClick={onClick} />
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */
);

const mapStateToProps = ({ shared, article }, ownProps) => ({
  pageView: shared.pageView,
  isChromeless: shared.isChromeless,
  article: (typeof article === 'object' && article.content?.[0]) || {},
  vertical: ownProps.vertical || shared.vertical,
});

// export before `@connect` for testing purposes
export class VideoPlayer extends Component {
  static contextType = VideoPlayerContext

  currentCompleteHandler = Function.prototype;

  static propTypes = {
    // Page Values
    pageView: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    article: articlePropType,
    video: videoPropType.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    autoPlay: PropTypes.bool,
    manualPlay: PropTypes.bool,
    onAdPlay: PropTypes.func,
    onAdEnd: PropTypes.func,
    onVideoPlay: PropTypes.func,
    onVideoPause: PropTypes.func,
    onVideoMute: PropTypes.func,
    onVideoUnmute: PropTypes.func,
    hasClickedToPlay: PropTypes.bool,
    /* eslint-enable react/no-unused-prop-types */
    continuousPlay: PropTypes.bool,
    replay: PropTypes.bool,
    playlist: PropTypes.arrayOf(videoPlaylistPropType),
    onVideoEnd: PropTypes.func,
    onRef: PropTypes.func,
    isChromeless: PropTypes.bool,
    playButtonStyle: PropTypes.string,
    centerPlayButtonAtMobile: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    isRailLayout: PropTypes.bool,
    isLiveVideoEmbed: PropTypes.bool,
    shouldStickWhilePaused: PropTypes.bool,
    shouldPauseWhenMutedAndOutOfView: PropTypes.bool,
    disableSticky: PropTypes.bool,
    disableStickyOnMute: PropTypes.bool,
    mute: PropTypes.bool,
    adPlayButtonPosition: PropTypes.oneOf(['default', 'bottomLeft']),
    loadingLazy: PropTypes.bool,
    pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
    pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),

    // Autoplay
    autoplayCapabilities: autoplayCapabilitiesPropType,
    autoplayTestGroup: PropTypes.string,
    isTestingAutoplayCapabilities: PropTypes.bool,
    onlyAutoplayMuted: PropTypes.bool,
  };

  static contextTypes = {
    setStickyVideoFlag: PropTypes.func,
    isLiveBlog: PropTypes.bool,
  };

  static defaultProps = {
    article: null,
    autoPlay: false,
    manualPlay: false,
    continuousPlay: false,
    replay: false,
    playlist: [],
    onAdPlay: Function.prototype,
    onAdEnd: Function.prototype,
    onVideoPlay: Function.prototype,
    onVideoPause: Function.prototype,
    onVideoEnd: Function.prototype,
    onVideoMute: Function.prototype,
    onVideoUnmute: Function.prototype,
    hasClickedToPlay: false,
    onRef: Function.prototype,
    isChromeless: false,
    isRailLayout: false,
    playButtonStyle: null,
    centerPlayButtonAtMobile: false,
    isEmbedded: false,
    isLiveVideoEmbed: false,
    shouldStickWhilePaused: false,
    shouldPauseWhenMutedAndOutOfView: false,
    disableSticky: false,
    disableStickyOnMute: false,
    mute: false,
    autoplayCapabilities: null,
    autoplayTestGroup: null,
    isTestingAutoplayCapabilities: false,
    onlyAutoplayMuted: false,
    adPlayButtonPosition: 'default',
    loadingLazy: null,
    pipAlignDesktop: 'bottom',
    pipAlignMobile: 'bottom',
  };

  constructor(props) {
    super(props);
    this.state = {
      // Play states
      isAdState: false,
      isAdPlaying: false,
      isContentPlaying: false,
      isLoading: false,
      isPausedAndOutOfView: false,
      isContentLoading: false,
      hasContentLoaded: false,
      showLoader: true,
      unmountLoader: true,

      // Video timing
      adPosition: 0,
      adDuration: 0,
      contentPosition: 0,
      contentDuration: 0,

      // Sticky player
      isStuck: false,
      isStickDisabled: false,

      // Controls
      renderControls: false,
      showPlayIcon: true,
      showReplayIcon: false,
      showTeaseImage: true,
      showCaptions: false,
      showControls: false,
      isFullscreen: false,
      adHeightOverride: false,
      controlTransition: 0,
      volumeLevel: 1,
      showTapUnmute: false,
      vprn: Math.floor((Math.random() * 1000000)).toString(),
    };
  }

  async componentDidMount() {
    const {
      continuousPlay,
      replay,
      video = {},
      playlist,
      onAdPlay,
      onRef,
      onVideoPlay,
      onVideoPause,
      onVideoEnd,
      autoPlay,
      article,
      autoplayTestGroup,
      manualPlay,
      vertical,
      isEmbedded,
      pageView,
      mute,
    } = this.props;

    const {
      volumeLevel,
      vprn,
    } = this.state;

    const videoAdConfig = getVideoAdConfig({
      article,
      video,
      continuousPlay,
      autoPlay,
      vprn,
      vertical,
      isEmbedded,
      pageView,
      referrer: document?.referrer,
      isMobile: MobileDetection.any(),
    });

    const isVideoPlayable = video.playable;

    if (autoplayTestGroup && ENABLE_AUTOPLAY_TEST === 'true') {
      // only add newscase if autoplayTestGroup is passed in and test is on
      videoAdConfig.siteData.newscase = AUTOPLAY_TEST_AD_KVS[autoplayTestGroup]
        || AUTOPLAY_TEST_AD_KVS[AUTOPLAY_TEST_GROUPS.AUTOPLAY_DISABLED];
    }

    // Remove once switched to BENTO-API
    // https://nbcnewsdigital.atlassian.net/browse/BENTO-1274
    if (video.hasCaptions) {
      video.captionLinks = video.closedCaptioning;
    }

    // Check For Presence of NDP Player
    // Conditional should NOT be $ndp object
    // but rather a model created by the NDP
    if (!window.NDPPlayer) {
      window.$ndp = { configuration: videoAdConfig };

      // TODO: this function being async and this await here for loading the script
      // causes the code after the await to potentially fire AFTER other React lifecycle
      // events. This should be cleaned up so the lifecycle and state are easier to reason
      // about.
      await loadScript(chooseNDPScript());
    }

    if (!video.mpxId) {
      video.mpxId = video.mpxMetadata.id;
    }

    this.asset = createNDPAsset(video);

    this.ndpPlayer = new window.NDPPlayer({ location: this.videoContainer });
    const { scopeEvents } = this;

    // Setup video analytics explicitly, not programatically
    $t('register', 'ndp_playerInit');
    const ndpData = {
      playerObj: this.ndpPlayer,
      brand: 'mbt',
    };
    $t('track', 'ndp_playerInit', ndpData);

    this.currentCompleteHandler = this.completeHandler(
      playlist,
      continuousPlay,
      onVideoEnd,
      replay,
    );

    const PlaybackEvent = {
      LOADED: scopeEvents((event) => {
        if (mute) {
          this.toggleMute();
          this.ndpPlayer.caption('english');
        }
        if (window) {
          window.NBC_AMP_TEST_END_TIME = Date.now();
          // eslint-disable-next-line
          console.log('AMP video perf testing end time', window.NBC_AMP_TEST_END_TIME);
        }
        $t('track', VIDEO_LOAD_ICON_TRACKING_EVENT, { showLoadIcon: true }); // eslint-disable-line no-undef
        const { payload: { totalLength: contentDuration } } = event;
        const { isStuck } = this.state;
        /*
            The ndp player attaches event listeners to its video and exposes
            those listeners to us with these playback events: LOADED, START, etc.
            However, on Android, (for whatever reason) we are not receiving any
            events except for LOADED. So on LOADED, for android, we will attach
            event listeners to the video ourselves for PLAY, PROGRESS, etc., and
            then pass the same callbacks to those that we use for NDP events.
        */
        if (MobileDetection.Android()) {
          this.videoElement = this.ndpPlayer.location().querySelector('video');
          this.addVideoTagEventListeners({ PlaybackEvent });
        }

        this.setState({
          contentDuration,
          showTeaseImage: false,
          showPlayIcon: false,
          showReplayIcon: false,
          renderControls: !isStuck,
          // the `loaded` ndp event isn't actually fired when the video is ready to play.
          // it's actually fired as soon as the ndp code itself gets the data about
          // the video you want to play, so we now have to show the loading spinner
          // until the `start` event is fired
          isLoading: true,
          unmountLoader: false,
          isContentLoading: true,

        });
      }),

      START: scopeEvents(({ payload: { totalLength: contentDuration } }) => {
        const { isStuck } = this.state;
        this.setState({
          showPlayIcon: false,
          showReplayIcon: false,
          renderControls: !isStuck, // show controls if not stuck
          contentDuration,
          adHeightOverride: false,
          isLoading: false,
          isContentLoading: true,
        });

        onVideoPlay();
        this.showControlRack();
      }),

      PROGRESS: scopeEvents(({ payload }) => {
        const { playheadPosition: contentPosition, totalLength: contentDuration } = payload;
        this.setState({
          contentPosition,
          contentDuration,
          isLoading: false,
          isContentPlaying: true,
          isContentLoading: false,
          hasContentLoaded: true,
        });
      }),

      SEEK: scopeEvents(({ payload: { playheadPosition: contentPosition } }) => {
        this.setState({
          contentPosition,
        });
      }),

      PLAY: scopeEvents(() => {
        this.setState({
          isContentPlaying: true,
          adHeightOverride: false,
          isAdState: false,
          isLoading: false,
          isContentLoading: false,
          hasContentLoaded: true,
        });

        onVideoPlay();
      }),

      PAUSE: scopeEvents(() => {
        this.setState({
          isContentPlaying: false,
        });

        onVideoPause();
      }),

      STOP: () => {
        this.setState({
          isContentPlaying: false,
          isAdState: false,
          isAdPlaying: false,
        });
      },

      COMPLETE: this.currentCompleteHandler,

      UNLOADED: scopeEvents(() => {
        this.setState({
          showTeaseImage: true,
          showPlayIcon: true,
          renderControls: false,
          isContentLoading: false,
          hasContentLoaded: true,
          isAdPlaying: false,
          isAdState: false,
          isContentPlaying: false,
        });
      }),
    };

    const AdEvent = {
      START: scopeEvents(({ payload: { totalLength: adDuration } }) => {
        this.setState({
          isAdState: true,
          showPlayIcon: false,
          showReplayIcon: false,
          showTeaseImage: false,
          isContentLoading: true,
          adDuration,
        });
        this.showControlRack();

        onAdPlay();
        this.setState({
          isLoading: false,
          hasContentLoaded: true,
        });
      }),

      PROGRESS: scopeEvents(({ payload }) => {
        const { playheadPosition: adPosition, totalLength: adDuration } = payload;
        this.setState({
          adPosition,
          adDuration,
          isLoading: false,
          isAdPlaying: true,
          isContentLoading: false,
          hasContentLoaded: true,
        });
      }),

      PLAY: scopeEvents(() => {
        this.setState({
          isAdPlaying: true,
          isLoading: false,
          isContentLoading: false,
          hasContentLoaded: true,
        });

        // Add height: 100% override if advert iframe does not have set height
        if (this.wrapper) {
          const vpaidSlot = document.querySelector('.fw_vpaid_slot', this.wrapper);
          if (vpaidSlot) {
            // Check bounding client and computed heights
            const boundingHeight = vpaidSlot.getBoundingClientRect().height;
            const computedHeight = parseInt(window.getComputedStyle(vpaidSlot).height, 10);
            if (!boundingHeight && (Number.isNaN(computedHeight) || !computedHeight)) {
              // Unable to find height
              this.setState({ adHeightOverride: true });
            }
          }
        }
      }),

      PAUSE: scopeEvents(() => {
        this.setState({
          isAdPlaying: false,
        });
      }),

      VOLUME_CHANGE: scopeEvents((data) => {
        const volume = get(data, 'payload.volume', 0);
        if (volume) this.toggleUnmute(volume);
      }),

      COMPLETE: scopeEvents(() => {
        this.setState({
          isAdState: false,
          isAdPlaying: false,
          adPosition: 0,
          adDuration: 0,
          controlTransition: 0,
          hasContentLoaded: true,
        });
      }),

      // Handle all ad fail events
      ERROR: () => {
        this.setState({
          isAdPlaying: false,
          isContentLoading: false,
          hasContentLoaded: true,
        });
      },

      TIMEOUT: () => {
        this.setState({
          isAdPlaying: false,
          isContentLoading: false,
          hasContentLoaded: true,
        });
      },

      ADSKIP: () => {
        this.setState({
          isAdPlaying: false,
          isContentLoading: false,
          hasContentLoaded: true,
        });
      },

      NOPREROLL: () => {
        this.setState({
          isAdPlaying: false,
          isContentLoading: false,
          hasContentLoaded: true,
        });
      },
    };

    this.videoEventListeners = { PlaybackEvent, AdEvent };

    this.ndpPlayer.on({ PlaybackEvent, AdEvent });

    if (manualPlay || (BrowserDetection.isIE() && autoPlay)) {
      this.ndpPlayer.play(this.asset);
    }

    // only attempt to autoplay if video isn't expired
    if (isVideoPlayable) {
      this.attemptToAutoplayIfConfigured();
    }

    if (!volumeLevel) {
      this.ndpPlayer.mute(true);
    }

    BTE.on('scroll', this.monitorSticky, 'debounce');
    BTE.on('scroll', this.monitorMutedAndOutOfView, 'debounce');

    // Attach handler for fullscreen events
    if (document.addEventListener) {
      ['webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange', 'MSFullscreenChange']
        .forEach((e) => document.addEventListener(e, this.handleFullscreen, false));
    }

    if (onRef) {
      onRef(this);
    }

    // Listen for global video playing event
    BTE.on(
      VIDEO_PLAYING_GLOBAL_EVENT,
      this.onEventGlobalVideoPlaying,
      VIDEO_PLAYING_EVENT_ATTENUATION,
    );

    BTE.on(
      VIDEO_RESERVE_PIP_GLOBAL_EVENT,
      this.onEventGlobalPIPReservation,
      VIDEO_PLAYING_EVENT_ATTENUATION,
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    video: nextVideo,
    playlist: nextPlaylist,
    continuousPlay: nextContinuousPlay,
    onVideoEnd,
  }) {
    const { video: currentVideo, playlist: currentPlaylist } = this.props;
    const { isAdPlaying, vprn } = this.state;

    if (this.ndpPlayer && !deepEqual(
      currentPlaylist?.[0],
      nextPlaylist?.[0],
    )) {
      this.ndpPlayer.off({
        NDP_PlaybackEvent_COMPLETE: this.currentCompleteHandler,
      });

      this.currentCompleteHandler = this.completeHandler(
        nextPlaylist,
        nextContinuousPlay,
        onVideoEnd,
      );

      this.ndpPlayer.on({
        NDP_PlaybackEvent_COMPLETE: this.currentCompleteHandler,
      });
    }

    if (nextVideo.id === currentVideo.id) {
      return;
    }

    // Check for captions in the next video
    // If the next video has captions we need to transform them
    // Remove once switched to BENTO-API
    // https://nbcnewsdigital.atlassian.net/browse/BENTO-1274
    const videoObject = { ...nextVideo };

    if (videoObject.hasCaptions) {
      videoObject.captionLinks = nextVideo.closedCaptioning;
    }

    if (isAdPlaying) {
      // try... catch is workaround for videoPackage error in Safari;
      // BENTO-4943
      try {
        this.ndpPlayer.stop();
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    const asset = createNDPAsset(videoObject);
    this.ndpPlayer.play(asset);

    this.setState({
      vprn: parseInt(vprn, 10) + 1,
      isStickDisabled: false,
      isAdPlaying: false,
      isAdState: false,
    }); // reset sticky close on new video
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isAdPlaying: wasAdPlaying,
      isContentPlaying: wasContentPlaying,
      contentPosition: prevContentPos,
      adPosition: prevAdPos,
      isContentLoading: wasContentLoading,
      isAdState: wasAdState,
    } = prevState;

    const {
      isAdPlaying,
      isContentPlaying,
      isContentLoading,
      currentPosition: curContentPos,
      adPosition: curAdPos,
      isAdState,
    } = this.state;

    const { isTestingAutoplayCapabilities, hasClickedToPlay, onAdEnd } = this.props;

    if (hasClickedToPlay && !isContentPlaying) {
      this.manualPlay();
    }

    if (wasAdState && !isAdState) {
      onAdEnd();
    }

    // Play status has changed, update sticky / inactivity
    if (isAdPlaying !== wasAdPlaying
      || isContentPlaying !== wasContentPlaying
      || isContentLoading !== wasContentLoading) {
      this.monitorSticky(undefined, false);
      InactivityMonitor.resetTimer();
    }

    // Reset Inactivity monitor on video progress
    if (curContentPos !== prevContentPos || curAdPos !== prevAdPos) {
      InactivityMonitor.resetTimer();
    }

    if (prevProps.isTestingAutoplayCapabilities && !isTestingAutoplayCapabilities) {
      // only try to autoplay if we were previously testing capabilities and now we're not
      this.attemptToAutoplayIfConfigured();
    }
  }

  componentWillUnmount() {
    BTE.remove('scroll', this.monitorSticky, 'debounce');
    BTE.remove('scroll', this.monitorMutedAndOutOfView, 'debounce');

    this.removeVideoTagEventListeners();

    const { onRef } = this.props;
    if (onRef) {
      onRef(undefined);
    }

    // Remove global video playing event listener
    BTE.remove(
      VIDEO_PLAYING_GLOBAL_EVENT,
      this.onEventGlobalVideoPlaying,
      VIDEO_PLAYING_EVENT_ATTENUATION,
    );

    BTE.remove(
      VIDEO_RESERVE_PIP_GLOBAL_EVENT,
      this.onEventGlobalPIPReservation,
      VIDEO_PLAYING_EVENT_ATTENUATION,
    );
  }

  setVideoContainerRef = (ref) => {
    this.videoContainer = ref;
  };

  onEventGlobalVideoPlaying = ({ ref }) => {
    const { isAdPlaying, isContentPlaying } = this.state;
    const playing = isAdPlaying || isContentPlaying;
    if (playing && ref && typeof ref.isEqualNode === 'function' && !ref.isEqualNode(this.wrapper)) {
      this.pause();
    }
  }

  onEventGlobalPIPReservation = ({ ref = {} }) => {
    const { isStickDisabled } = this.state;
    if (typeof ref.isEqualNode === 'function' && !ref.isEqualNode(this.wrapper)) {
      if (!isStickDisabled) {
        this.setStickyVideoFlag(false);
        this.setState({
          isStuck: false,
          isStickDisabled: true,
        });
      }
    } else if (isStickDisabled) {
      this.setState({ isStickDisabled: false });
    }
  }

  attemptToAutoplayIfConfigured = () => {
    const {
      autoplayCapabilities,
      autoplayTestGroup,
      onlyAutoplayMuted,
      autoPlay,
    } = this.props;

    const {
      canAutoplayUnmuted,
      canAutoplayMuted,
    } = autoplayCapabilities || {};

    const isNotSafari = !BrowserDetection.isSafari();

    // if the browser is not Safari we want to run this check
    if (isNotSafari && (!canAutoplayMuted && !canAutoplayUnmuted)) {
      return;
    }

    if (autoPlay) {
      $t('register', 'ndp_playerAutoplayMuted');
      if (!this.ndpPlayer) {
        return;
      }
      if (canAutoplayUnmuted && !onlyAutoplayMuted) {
        this.ndpPlayer.mediaAsset(this.asset);
        this.play();
        $t('track', 'ndp_playerAutoplayMuted');
      } else if (onlyAutoplayMuted || canAutoplayMuted) {
        this.ndpPlayer.mediaAsset(this.asset);
        this.toggleMute();
        this.play();
        $t('track', 'ndp_playerAutoplayMuted');
      }
    } else {
      if (
      // TODO: remove this feature flag when the autoplay feature has stabilized
        (ENABLE_AUTOPLAY_TEST !== 'true' && !getUpdatedQueryParamStatus('videoAutoplayTest'))
        // these first 2 checks should shut down any asynchronous race conditions between
        // NDP loading and the autoplayCapabilities check happening
        || !this.ndpPlayer
        || !autoplayCapabilities
        // if no autoplayTestGroup was passed through, don't even try to autoplay
        || !autoplayTestGroup
        || autoplayTestGroup === AUTOPLAY_TEST_GROUPS.AUTOPLAY_DISABLED
      ) {
        return;
      }

      const shouldAutoplayUnmutedOnly = (
        autoplayTestGroup === AUTOPLAY_TEST_GROUPS.AUTOPLAY_UNMUTED_ONLY
      );
      const shouldAutoplayAnyVolume = (
        autoplayTestGroup === AUTOPLAY_TEST_GROUPS.AUTOPLAY_ANY
      );

      $t('register', 'ndp_playerAutoplayUnmuted');
      if (canAutoplayUnmuted && (shouldAutoplayUnmutedOnly || shouldAutoplayAnyVolume)) {
        this.ndpPlayer.mediaAsset(this.asset);
        this.play();
        $t('track', 'ndp_playerAutoplayUnmuted');
      } else if (canAutoplayMuted && shouldAutoplayAnyVolume) {
        this.toggleMute();
        this.ndpPlayer.mediaAsset(this.asset);
        this.play();
        $t('track', 'ndp_playerAutoplayMuted');
      }
    }
  }

  setStickyVideoFlag = (flag) => {
    const { setStickyVideoFlag } = this.context;
    if (setStickyVideoFlag) {
      setStickyVideoFlag(flag);
    }
  };

  nativeProgressListener = (e) => {
    const { isAdPlaying } = this.state;
    if (isAdPlaying) return;

    const oneSecond = 1000;
    const playheadPosition = Math.round((e.currentTarget.currentTime || 0) * oneSecond);
    const totalLength = Math.round((e.currentTarget.duration || 0) * oneSecond);

    if (playheadPosition === 0 && totalLength === 0) return;

    const payload = {
      playerId: this.ndpPlayer.id(),
      playheadPosition,
      totalLength,
    };
    this.videoEventListeners.PlaybackEvent.PROGRESS({ payload });
  };

  nativePlayListener = (e) => {
    const { isAdPlaying } = this.state;
    if (isAdPlaying) return;

    const oneSecond = 1000;
    const payload = {
      playerId: this.ndpPlayer.id(),
      totalLength: Math.round((e.currentTarget.duration || 0) * oneSecond),
    };
    this.videoEventListeners.PlaybackEvent.START({ payload });
  };

  nativePauseListener = () => {
    this.videoEventListeners.PlaybackEvent.PAUSE({
      payload: {
        playerId: this.ndpPlayer.id(),
      },
    });
  };

  addVideoTagEventListeners = () => {
    if (this.videoElement) {
      this.videoElement.addEventListener('timeupdate', this.nativeProgressListener);
      this.videoElement.addEventListener('play', this.nativePlayListener);
      this.videoElement.addEventListener('pause', this.nativePauseListener);
    }
  };

  removeVideoTagEventListeners = () => {
    if (this.videoElement) {
      this.videoElement.removeEventListener('timeupdate', this.nativeProgressListener);
      this.videoElement.removeEventListener('play', this.nativePlayListener);
      this.videoElement.removeEventListener('pause', this.nativePauseListener);
    }
  }

  showControlRack = () => {
    const { renderControls } = this.state;
    if (!renderControls) return;

    this.setState({
      showControls: true,
      controlTransition: setTimeout(this.hideControlRack, 3500),
    });
  }

  hideControlRack = () => {
    const { renderControls, controlTransition } = this.state;
    if (controlTransition && !renderControls) return;
    this.setState({
      showControls: false,
      controlTransition: 0,
    });
  }

  playerInteraction = () => {
    const { isChromeless } = this.props;
    // don't do anything for in mobile webview
    // tease image click event will handle manualPlay
    if (isChromeless) return;

    this.showControlRack();
  }

  handleVideoViewportClick = () => {
    const { isAdState, isContentPlaying, renderControls } = this.state;
    if (isAdState || !renderControls) return;

    if (isContentPlaying) this.pause();
    else this.play();
  };

  toggleMute = () => {
    const { onVideoMute } = this.props;
    onVideoMute();

    this.ndpPlayer.mute(true);
    if (this.ndpPlayer.volume()) {
      this.ndpPlayer.volume(0);
    }
    this.setState({
      showTapUnmute: true,
      volumeLevel: 0,
    });
  }

  toggleUnmute = (volume) => {
    const { onVideoUnmute } = this.props;
    onVideoUnmute();

    this.ndpPlayer.mute(false);
    if (!this.ndpPlayer.volume()) {
      this.ndpPlayer.volume(volume);
    }
    this.setState({
      showTapUnmute: false,
      volumeLevel: volume,
    });
  }

  toggleTooltip = () => {
    this.setState(({ showCaptions }) => ({ showCaptions: !showCaptions }));
  }

  stickVideo = (track = true) => {
    const { pageView } = this.props;
    this.setStickyVideoFlag(true);
    this.setState({
      isStuck: true,
      renderControls: false,
    });
    BTE.trigger(
      VIDEO_RESERVE_PIP_GLOBAL_EVENT,
      { ref: this.wrapper },
      VIDEO_PLAYING_EVENT_ATTENUATION,
    );
    // Event on video stick (only fronts, covers, and articles)
    if (track && pageView !== 'video') {
      $t('register', 'ndp_playerStick');
      $t('track', 'ndp_playerStick');
    }
  }

  unstickVideo = () => {
    this.setStickyVideoFlag(false);
    this.setState({
      isStuck: false,
      renderControls: true,
    });
  }

  isVideoScrolledOutOfView = (depth) => {
    const navbarHeight = getNavbarHeight(Breakpoints);
    const { isFullscreen } = this.state;

    const playerParent = this.wrapper.parentElement; // containing element from parent component

    // Get dimensions of the player container to check relative scroll position
    const { top: containerTop, height: containerHeight } = playerParent.getBoundingClientRect();

    const topOffset = containerTop + depth; // top coordinate of player container
    const bottomOffset = topOffset + containerHeight; // bottom coordinate of player container

    return (
      (bottomOffset < (depth + navbarHeight)) // scrollY is below the bottom of the container
      || (topOffset > (depth + window.innerHeight)) // scrollY is above the top of the container
    ) && !isFullscreen; // and is not in fullscreen mode
  }

  scopeEvents = (callback) => (...data) => {
    const activePlayerId = (data[0]).payload.playerId;
    if (this.ndpPlayer.id() === activePlayerId) callback(...data);
  }

  completeHandler = (
    playlist,
    continuousPlay,
    onVideoEnd,
    replay,
  ) => this.scopeEvents(() => {
    InactivityMonitor.resetTimer();
    const { isFullscreen } = this.state;

    if (replay) {
      const { video: currentVideo } = this.props;

      // Close fullscreen mode on continuousPlay
      if (isFullscreen) {
        this.fullscreen();
      }

      this.setState({
        showTeaseImage: true,
        showReplayIcon: true,
        isContentPlaying: false,
        showControls: false,
      });

      onVideoEnd(currentVideo);
    }

    // Play next video if we have a playlist and continuousPlay;
    if (playlist[0] && continuousPlay) {
      const { video: currentVideo } = this.props;
      const currIndex = playlist[0].videos.findIndex((vid) => vid.id === currentVideo.id);

      const nextVideoToPlay = {
        ...(playlist[0].videos[currIndex + 1] || playlist[0].videos[0]),
        // Set isContinous play as initiate state
        isContinuousPlay: true,
        // Set playlist as next video's asssociated
        associatedVideoPlaylist: playlist[0],
      };

      const FSNext = () => {
        onVideoEnd(nextVideoToPlay);
        ['webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange', 'MSFullscreenChange']
          .forEach((e) => document.removeEventListener(e, FSNext, false));
      };

      // Close fullscreen mode on continuousPlay
      if (isFullscreen) {
        this.fullscreen();

        if (document.addEventListener) {
          [
            'webkitfullscreenchange',
            'mozfullscreenchange',
            'fullscreenchange',
            'MSFullscreenChange',
          ].forEach((e) => document.addEventListener(e, FSNext, false));
        }
      } else {
        onVideoEnd(nextVideoToPlay);
      }
    }
  });

  play = () => {
    this.ndpPlayer.play();
    this.setState({ isStickDisabled: false, isContentPlaying: true });

    // Trigger global video playing event
    BTE.trigger(
      VIDEO_PLAYING_GLOBAL_EVENT,
      { ref: this.wrapper },
      VIDEO_PLAYING_EVENT_ATTENUATION,
    );
  }

  pause = () => {
    this.ndpPlayer.pause();

    const { showReplayIcon } = this.state;
    if (showReplayIcon) {
      this.setState({ showReplayIcon: false, showTeaseImage: false });
    }
  }

  manualPlay = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const { isChromeless } = this.props;
    if (isChromeless) {
      MobileApi.viewVideo(this.asset.raw.id);
      return;
    }

    if (this.ndpPlayer.mediaAsset()) {
      this.ndpPlayer.pause();
    }

    this.ndpPlayer.mediaAsset(this.asset);
    this.unmute();
    this.play();

    $t('register', 'ndp_playerManualPlay');
    $t('track', 'ndp_playerManualPlay');
  }

  changeVolume = (e) => {
    // get dBFS from the input slider
    const dBFS = parseInt(e.target.value, 10);

    // convert dBFS to a gain value. using dBFS because it is more realistic to how loudness is
    // perceived. using gain leads to a minimal change in loudness until almost the very bottom of
    // the slider. we also "round" down to 0 gain at the bottom of the slider.
    const gain = dBFS === loudnessMinimum
      ? 0
      : dBFSToGain(dBFS);

    const { showTapUnmute } = this.state;
    // if it is not muted but the gain is 0, we trigger mute.
    if (gain === 0 && !showTapUnmute) {
      this.toggleMute();
    } else if (gain > 0 && showTapUnmute) {
      // if gain is not 0 but it is muted, we trigger unmute
      this.toggleUnmute(gain);
    } else {
      // just changing the volume.
      this.ndpPlayer.volume(gain);
      this.setState({
        volumeLevel: gain,
      });
    }
  };

  unmute = () => {
    this.toggleUnmute(1);
  };

  scrub = (percentage) => {
    this.ndpPlayer.scrub(percentage);
  }

  fullscreen = () => {
    const { isFullscreen } = this.state;
    this.ndpPlayer.fullscreen(!isFullscreen);
  }

  handleFullscreen = () => {
    // Check if an element is in fullscreen
    // Move controls based on FS state
    if (
      document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement
    ) {
      this.setState({ isFullscreen: true });
      this.ndpPlayer.container().firstChild.appendChild(this.videoControls);
    } else {
      this.setState({ isFullscreen: false });
      this.ndpPlayer.location().parentNode.appendChild(this.videoControls);
    }
  }

  close = () => {
    this.unstickVideo();
    this.pause();
    this.setState({ isStickDisabled: true });

    // Event on video unstick (only fronts, covers, and articles)
    const { pageView } = this.props;
    if (pageView !== 'video') {
      $t('register', 'ndp_playerUnStick');
      $t('track', 'ndp_playerUnStick');
    }
  }

  captionsCard = () => {
    this.ndpPlayer.captionOptions();
  }

  monitorSticky = (depth = window.pageYOffset, trackAnalytics = true) => {
    const {
      isStuck,
      isStickDisabled,
      isAdPlaying,
      isContentPlaying,
      isContentLoading,
      volumeLevel,
    } = this.state;
    const { shouldStickWhilePaused, disableStickyOnMute } = this.props;

    if (isStickDisabled) return;

    const isPlaying = isAdPlaying || isContentPlaying || isContentLoading;
    const shouldStick = (isPlaying || shouldStickWhilePaused)
      && !(volumeLevel === 0 && disableStickyOnMute)
      && this.isVideoScrolledOutOfView(depth);

    if (isStuck !== shouldStick) {
      (shouldStick ? this.stickVideo : this.unstickVideo)(trackAnalytics);
    }
  }

  monitorMutedAndOutOfView = (depth = window.pageYOffset) => {
    const {
      volumeLevel,
      isAdPlaying,
      isContentPlaying,
      isContentLoading,
      isPausedAndOutOfView,
    } = this.state;

    const {
      shouldPauseWhenMutedAndOutOfView: shouldPause,
      disableStickyOnMute,
      disableSticky,
    } = this.props;

    const stickyDisabled = disableSticky || disableStickyOnMute;

    // should not pause when scrolled out of view if sticky player is present
    if (!stickyDisabled) return;

    const isMuted = volumeLevel === 0;
    const isPlaying = isAdPlaying || isContentPlaying || isContentLoading;

    if (this.isVideoScrolledOutOfView(depth) && isMuted && isPlaying && shouldPause) {
      this.setState({
        isPausedAndOutOfView: true,
      });
      this.pause();
    }

    // play video when scrolled back into view
    if (!this.isVideoScrolledOutOfView(depth) && isPausedAndOutOfView && shouldPause) {
      this.setState({
        isPausedAndOutOfView: false,
      });
      this.play();
    }
  }

  adPlayButton = () => {
    // renders adPlay button in bottom left instead of default position
    const { isAdPlaying, isAdState } = this.state;
    const { adPlayButtonPosition } = this.props;

    if (!isAdState || adPlayButtonPosition === 'default') {
      return null;
    }

    return (
      <div className={classNames(styles.adPlay, styles.adPlayBottomLeft)}>
        {!isAdPlaying && button('play', this.play, styles.adIcon)}
        {isAdPlaying && button('pause', this.pause, styles.adIcon)}
      </div>
    );
  }

  unmuteTap() {
    const {
      showTapUnmute, isFullscreen, isLoading, isStuck, showTeaseImage,
    } = this.state;
    const {
      disableSticky, isLiveVideoEmbed, isRailLayout, video,
    } = this.props;
    const isMini = !disableSticky && isStuck && isFullscreen && isLiveVideoEmbed;
    const isLive = isVideoLive(video);
    const showMute = !(!showTapUnmute || isLoading || showTeaseImage);

    return (
      <VideoUnmuteButton
        isLive={isLive}
        isMini={isMini}
        isRail={isRailLayout}
        show={showMute}
        onClickUnmute={this.unmute}
      />
    );
  }

  adControls() {
    const { isAdState } = this.state;
    if (!isAdState) {
      return null;
    }

    const { adPlayButtonPosition } = this.props;

    const { isAdPlaying, adPosition, adDuration } = this.state;

    return (
      <div className={classNames(styles.adControls, styles[adPlayButtonPosition])}>
        <div className={styles.adTime}>
          <Trans>Your Video Begins in</Trans>
          {' '}
          <span className={styles.time}>
            {formatDuration(adDuration - adPosition)}
          </span>
        </div>
        {adPlayButtonPosition === 'default' && (
          <div className={styles.adPlay}>
            {!isAdPlaying && button('play', this.play, styles.adIcon)}
            {isAdPlaying && button('pause', this.pause, styles.adIcon)}
          </div>
        )}
      </div>
    );
  }

  // Captions Options
  captionTooltip() {
    const { showCaptions } = this.state;
    const { vertical } = this.props;
    if (!showCaptions) {
      return null;
    }

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
      <div className={styles.tooltip}>
        <li
          onClick={() => {
            this.ndpPlayer.captionOptions();
            this.toggleTooltip();
          }}
        >
          <Trans>Options</Trans>
        </li>
        <li
          onClick={() => {
            this.ndpPlayer.caption(true);
            this.toggleTooltip();
          }}
        >
          <Trans>
            {isTelemundoVertical(vertical) ? 'Spanish' : 'English'}
          </Trans>
        </li>
        <li
          onClick={() => {
            this.ndpPlayer.caption(false);
            this.toggleTooltip();
          }}
        >
          <Trans>Off</Trans>
        </li>
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  }

  controls() {
    const {
      isAdState,
      isContentPlaying,
      renderControls,
      contentPosition,
      contentDuration,
      volumeLevel,
      isFullscreen,
    } = this.state;

    if (!renderControls || isAdState) {
      return null;
    }

    const { video, vertical } = this.props;

    const isToday = vertical === 'today';

    const hasCaptions = video.hasCaptions
      || (this.ndpPlayer?.dataStore?.caption);

    const isLive = isVideoLive(video);

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <div
        className={classNames(styles.controls, { [styles.isLive]: isLive })}
        data-testid="video-controls"
        ref={(ref) => { this.videoControls = ref; }}
      >
        <ProgressBar
          currentPosition={contentPosition}
          totalDuration={contentDuration}
          onScrub={this.scrub}
          isLive={isLive}
        />
        <div data-testid="video-player__play-btn">
          {!isContentPlaying && button('play', this.play, styles.icon)}
          {isContentPlaying && button('pause', this.pause, styles.icon)}

          { !isLive && (
            <span className={styles.time}>
              {formatDuration(contentPosition)}
              {' '}
              /
              {formatDuration(contentDuration)}
            </span>
          ) }

          {isLive && (
            <span className={styles.time}>
              <Trans>LIVE</Trans>
            </span>
          )}

          <div className={styles.tools}>
            {!MobileDetection.any() && (
              <span className={styles.icon} data-testid="volume-icon">
                {!volumeLevel
                  && button(
                    'volume-level-0',
                    this.unmute,
                    classNames(styles.icon, styles.volumeButton),
                  )}
                {!!volumeLevel
                  && button(
                    'volume-level-2',
                    this.toggleMute,
                    classNames(styles.icon, styles.volumeButton),
                  )}
                {/* TODO: make the volume input range a controlled component
                  instead of using defaultValue */}
                {/*
                  The scale here is using dBFS, which should be thought of as "loudness reduction,"
                  that's why 0 is the maximum. At 0, we want "no loudness reduction" aka full volume
                */}
                <input
                  type="range"
                  defaultValue={gainToDBFS(volumeLevel)}
                  min={loudnessMinimum}
                  max="0"
                  step="1"
                  className={styles.volume}
                  onChange={this.changeVolume}
                  data-testid="volume-slider"
                />
              </span>
            )}

            {hasCaptions
              && (
                <span
                  className={`${styles.icon} icon icon-caption-off ${styles.captions}`}
                  data-testid="video-player__caption-tooltip-btn"
                  onClick={this.toggleTooltip}
                >
                  {this.captionTooltip()}
                </span>
              )}
            {!isFullscreen && button(isToday ? 'full-screen-alt' : 'full-screen', this.fullscreen, styles.icon)}
            {isFullscreen && button(isToday ? 'full-screen-close-alt' : 'full-screen-close', this.fullscreen, styles.icon)}
          </div>
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }

  stickyCloseIcon() {
    const { isStuck } = this.state;
    if (!isStuck) return null;

    return (
      <div className={styles.close}>
        {button('close', this.close, styles.closeIcon)}
      </div>
    );
  }

  dismissLoaderOnAnimationEnd() {
    const { showLoader } = this.state;
    if (showLoader) {
      this.setState({
        showLoader: false,
      });
    }
  }

  unmountLoaderOnAnimationEnd() {
    const { showLoader } = this.state;
    if (!showLoader) {
      this.setState({
        unmountLoader: true,
      });
    }
  }

  render() {
    const {
      video = {},
      isChromeless,
      disableSticky,
      pageView,
      vertical,
      centerPlayButtonAtMobile,
      playButtonStyle,
      isRailLayout,
      isLiveVideoEmbed,
      autoPlay,
      loadingLazy,
      pipAlignDesktop,
      pipAlignMobile,
    } = this.props;

    const {
      isStuck,
      isLoading,
      unmountLoader,
      showLoader,
      showControls,
      isFullscreen,
      adHeightOverride,
      isAdPlaying,
      isAdState,
      isContentPlaying,
      hasContentLoaded,
      showTeaseImage,
      showPlayIcon,
      showReplayIcon,
    } = this.state;

    const { isLiveBlog } = this.context;

    const { mpxMetadata: { id } } = video;

    const className = classNames(
      block,
      styles.videoPlayer,
      {
        isStuck: !disableSticky && isStuck, // needed for out of module targeting
        adIsActive: isAdState,
        [styles.adNotPlaying]: !isAdPlaying,
        [styles.adIsPlaying]: isAdPlaying,
        [styles.stuck]: !disableSticky && isStuck,
        [styles.stuckLB]: !disableSticky && isStuck && isLiveBlog,
        [styles.showControls]: showControls,
        [styles.liveVideoEmbed]: isLiveVideoEmbed,
        [styles.liveVideoEmbedRail]: isLiveVideoEmbed && isRailLayout,
        [`${styles.fullScreen} fullScreen`]: isFullscreen,
        adHeightOverride,
        [styles.mobile]: isChromeless,
        [styles.alignDesktopTop]: pipAlignDesktop === 'top',
        [styles.alignDesktopBottom]: pipAlignDesktop === 'bottom',
        [styles.alignMobileTop]: pipAlignMobile === 'top',
        [styles.alignMobileBottom]: pipAlignMobile === 'bottom',
      },
    );

    let loaderBrand = 'default';
    if (vertical === 'today') {
      loaderBrand = 'today';
    } else if (vertical === 'msnbc' || vertical === 'news') {
      loaderBrand = 'news';
    }

    const overwriteVideoSlatePosition = isContentPlaying && !hasContentLoaded
      ? 'absolute'
      : null;
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <>
        <Head>
          <link rel="preconnect" href={ndpOrigin} />
          <link rel="preconnect" href="https://link.theplatform.com" />
          <link rel="preconnect" href="https://mssl.fwmrm.net" />
          <link rel="preconnect" href="https://search.spotxchange.com" />
          <link rel="preconnect" href="https://tracker.nbcuas.com" />
          <link rel="preconnect" href="https://sb.scorecardresearch.com" />
          <link rel="preconnect" href="https://29773.v.fwmrm.net" />
        </Head>

        <div
          ref={(ref) => { this.wrapper = ref; }}
          className={className}
          onMouseEnter={this.showControlRack}
          onMouseMove={this.showControlRack}
          onClick={this.playerInteraction}
          onTouchStart={this.playerInteraction}
          onTouchMove={this.playerInteraction}
          data-test="video-player"
          data-testid={autoPlay ? 'video-player-autoplay' : 'video-player'}
        >
          <VideoSlate
            video={video}
            onPlayClick={this.manualPlay}
            pageView={pageView}
            vertical={vertical}
            playButtonStyle={playButtonStyle}
            centerPlayButtonAtMobile={centerPlayButtonAtMobile}
            showTeaseImage={showTeaseImage}
            showPlayIcon={showPlayIcon}
            showReplayIcon={showReplayIcon}
            isContentPlaying={isContentPlaying}
            isLoading={isLoading}
            isRailLayout={isRailLayout}
            positionStyleValue={overwriteVideoSlatePosition}
            isAutoPlay={autoPlay}
            loadingLazy={loadingLazy}
          />
          <PlayerViewport
            inputRef={this.setVideoContainerRef}
            id={id}
            viewportClickHandler={this.handleVideoViewportClick}
          />
          {this.adControls()}
          {this.controls()}
          {this.unmuteTap()}
          {this.stickyCloseIcon()}
          {this.adPlayButton()}
          {(isLoading || !unmountLoader)
            && (
              <div
                className={classNames(
                  styles.spinnerLoader,
                  { [styles.isDismissed]: showLoader === false },
                )}
                onAnimationEnd={() => this.unmountLoaderOnAnimationEnd()}
                data-testid="video-player__loading-spinner"
              >
                <IconLoader
                  className="relative z-2"
                  brand={loaderBrand}
                  animateIn
                  animateOut
                  showLoader={isLoading}
                  onAnimationEnd={() => this.dismissLoaderOnAnimationEnd()}
                />
              </div>
            )}
        </div>
      </>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }
}

export default connect(mapStateToProps)(VideoPlayer);
