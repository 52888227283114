import { selectEMPAssetType } from './selectEMPAssetType';

const buildVideoAsset = (video) => {
  const { mpxMetadata = {}, videoAssets = [] } = video;
  const mpxId = mpxMetadata.mpxId || video.mpxId || null;
  const guid = mpxMetadata.externalAdvertiserId || mpxMetadata.guid || video.guid || null;
  return {
    ...video,
    videoAssets: selectEMPAssetType(videoAssets),
    guid,
    mpxId,
  };
};

export { buildVideoAsset };
