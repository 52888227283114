import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import { attribution } from 'lib/social';
import { stub as $t } from '@nbcnews/analytics-framework';

import styles from './styles.module.scss';

const EVENT_NAME = 'mbt_video_socialShare';

$t('register', EVENT_NAME);

const track = (platform) => {
  $t('track', EVENT_NAME, { platform });
};

const mapStateToProps = ({ shared }) => ({
  socialMediaProfiles: shared.socialMediaProfiles,
});

@connect(mapStateToProps)
class SocialShareInline extends Component {
  static propTypes = {
    content: CustomPropTypes.content.isRequired,
    socialMediaProfiles: CustomPropTypes.socialMediaProfiles.isRequired,
    placement: PropTypes.string,
    propStyles: PropTypes.shape({
      share: PropTypes.string,
      shareExpanded: PropTypes.string,
      shareExpander: PropTypes.string,
      shareDot: PropTypes.string,
      shareClose: PropTypes.string,
      shareList: PropTypes.string,
      shareIcon: PropTypes.string,
    }),
    vertical: PropTypes.string,
  };

  static defaultProps = {
    placement: null,
    propStyles: {},
    vertical: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      shareExpanded: false,
    };
  }

  render() {
    const { shareExpanded } = this.state;
    const {
      content: {
        headline, urls, url: bentoUrl, shortUrl,
      },
      placement,
      propStyles,
      socialMediaProfiles,
      vertical,
    } = this.props;

    const url = (urls?.short) || (bentoUrl?.short) || shortUrl;
    const shareAttrib = attribution(socialMediaProfiles, vertical);

    const shareProps = {
      className: styles.shareLink,
      target: '_blank',
      rel: 'noopen noreferrer',
    };

    return (
      <div
        className={classNames(
          styles.share,
          propStyles.share,
          {
            [styles[placement]]: placement || false,
            [styles.shareExpanded]: shareExpanded,
            [propStyles.shareExpanded]: shareExpanded,
          },
          'dn-print',
        )}
        data-testid="social-share-inline"
      >
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        <div
          className={classNames(
            styles.shareExpander,
            propStyles.shareExpander,
            {
              [styles.hoverEffect]: !shareExpanded,
            },
          )}
          onClick={() => this.setState({ shareExpanded: !shareExpanded })}
        >
          {/* eslint-enable */}
          <span className={classNames(styles.shareDot, propStyles.shareDot)} />
          <svg
            className={classNames(styles.shareClose, propStyles.shareClose)}
            viewBox="0 0 15 15"
          >
            <line className={styles.shareLine} x1="0" x2="15" y1="0" y2="15" />
            <line className={styles.shareLine} x1="0" x2="15" y1="15" y2="0" />
          </svg>
        </div>
        <div
          className={classNames(styles.shareList, propStyles.shareList, {
            [styles[placement]]: placement || false,
          })}
        >
          {/* TODO, can this be replaced with <SocialShare /> */}
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            onClick={() => {
              track('facebook');
            }}
            {...shareProps}
          >
            <span
              className={classNames(
                'icon',
                'icon-facebook',
                styles.shareIcon,
                propStyles.shareIcon,
              )}
            />
          </a>
          <a
            // eslint-disable-next-line max-len
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(headline.social)}&via=${shareAttrib}&url=${url}&original_referer=${url}`}
            onClick={() => {
              track('twitter');
            }}
            {...shareProps}
          >
            <span
              className={classNames(
                'icon',
                'icon-twitter',
                styles.shareIcon,
                propStyles.shareIcon,
              )}
            />
          </a>
          <a
            href={`mailto:?subject=${
              headline.actual || headline.primary
            }&body=${url}`}
            onClick={() => {
              track('email');
            }}
            {...shareProps}
          >
            <span
              className={classNames(
                'icon',
                'icon-email',
                styles.shareIcon,
                propStyles.shareIcon,
              )}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default SocialShareInline;
