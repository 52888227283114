function getElementDistanceFromTopOfViewport(element) {
  if (!element) return null;

  let yPosition = 0;
  let currParent = element;

  while (currParent) {
    yPosition += (currParent.offsetTop - (currParent.scrollTop + currParent.clientTop));
    currParent = currParent.offsetParent;
  }

  return yPosition;
}

function getElementClickPosition({ clientX, target }) {
  const rect = target.getBoundingClientRect();
  const left = clientX - rect.left;
  const percentage = left / rect.width;

  return {
    percentage,
    left,
  };
}

function getElementYViewability(ele) {
  if (!ele) return 0;

  const { top, height } = ele.getBoundingClientRect();
  const posY = top * -1;
  const isInView = height > posY;
  const pixInView = (posY - height) * -1;
  const percentage = pixInView / height;

  return isInView ? percentage : 0;
}

function getElementHeight(selOrEle) {
  if (!selOrEle) return 0;

  let ele;

  if (typeof selOrEle === 'string') {
    ele = document.getElementsByClassName(selOrEle)[0] || document.querySelector(selOrEle);
  } else {
    ele = selOrEle;
  }

  const { height } = ele.getBoundingClientRect();

  return height || 0;
}

/*
  watchForElementAtTopOfViewport(
    element: DOM Element
    processorFunc: function
      optional, takes in (distanceFromTop, height) for user to process.
      i.e. if we didn't wanna track the top of the element but rather the middle
*/

function watchForElementAtTopOfViewport(element, processorFunc) {
  if (!element) return false;

  const elementDistanceFromTop = getElementDistanceFromTopOfViewport(element);
  const elementHeight = element.offsetHeight;
  const topOfViewport = window.pageYOffset;

  const positionToTrack = processorFunc
    ? processorFunc(elementDistanceFromTop, elementHeight)
    : elementDistanceFromTop;
  const scrollPositionReached = positionToTrack <= topOfViewport;

  if (scrollPositionReached) {
    return true;
  }

  return false;
}

export {
  getElementDistanceFromTopOfViewport,
  getElementClickPosition,
  getElementYViewability,
  getElementHeight,
  watchForElementAtTopOfViewport,
};
