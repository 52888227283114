import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const isDevelop = process.env.NODE_ENV === 'development';

const devToolsProps = {
  name: 'VideoCurrentPlayer',
  anonymousActionType: 'action',
  serialize: true,
};

const middlewares = (f) => (isDevelop ? devtools(f, devToolsProps) : f);

const store = (set) => ({
  currentPlayerId: undefined,
  /**
   * Request to be the current player.  This method is useful if a player wants to request
   * to play but only if nothing else is playing.  A typical example is when a player is set
   * up to automatically start playing when it is becomes visible.  If another player is already
   * playing then the player should not start playing.
   *
   * @param {string} playerId
   */
  request(playerId) {
    let success = false;
    set((state) => {
      success = state.currentPlayerId === undefined;
      return { currentPlayerId: state.currentPlayerId ?? playerId };
    });
    return success;
  },
  /**
   * Notify that the player is playing, regardless if another player is already current.  This
   * is generally useful for user-initiated play actions, where the expected behavior is that
   * other players should stop playing.
   *
   * @param {string} playerId
   */
  force(playerId) {
    set(() => ({ currentPlayerId: playerId }));
  },
  /**
   * Set the current player to "none".  This is generally useful for when a player wants to pause
   * or stop.
   *
   * @param {string} playerId
   */
  release(playerId) {
    set((state) => ({
      currentPlayerId: state.currentPlayerId === playerId ? undefined : state.currentPlayerId,
    }));
  },
});

export const useVideoCurrentPlayerStore = create(middlewares(store));
