import { timeToSeconds } from 'lib/DateTime';
import MobileDetection from 'lib/MobileDetection';
import { getCloudinaryImageSrc } from 'lib/imageUtils';
import { AIMS_FLAVORS } from 'lib/aimsFlavors';
import { toGammaPlaylistItem } from './toGammaPlaylistItem';
import { getBrandAdConfig } from './getBrandAdConfig';


/**
 * @param {object} breakpoints
 * @param {boolean} breakpoints.isM
 * @param {boolean} breakpoints.isL
 * @param {boolean} breakpoints.isXL
 * @returns string
 */
export function getFlavorByBreakpoint({ isM, isL, isXL }) {
  if (isXL || isL) return AIMS_FLAVORS.FOCAL_860x484;
  if (isM) return AIMS_FLAVORS.FOCAL_1000x563;
  return AIMS_FLAVORS.FOCAL_760x428;
}

/**
 * @param {VideoData} video
 * @param {string} flavor
 * @param {boolean} enableRetina
 * @returns {{
 *  teaseImageUrl: string,
 *  teaseImageAltText: string,
 * }}
 */
function selectTeaseImage(video, flavor, enableRetina) {
  const scaleFactor = enableRetina ? 2 : 1;
  const quality = enableRetina ? 'eco' : 'best';
  const teaseImageUrl = video.teaseImage?.url?.primary;
  if (teaseImageUrl) {
    return {
      teaseImageUrl: getCloudinaryImageSrc(teaseImageUrl, flavor, quality, scaleFactor),
      teaseImageAltText: video.teaseImage?.altText ?? '',
    };
  }

  const primaryImageUrl = video.primaryImage?.url?.primary;

  return {
    // teaseImageUrl: getCloudinaryImageSrc(primaryImageUrl, AIMS_FLAVORS.FOCAL_860x484, quality, scaleFactor),
    teaseImageUrl: primaryImageUrl,
    teaseImageAltText: video.primaryImage?.altText || '',
  };
}

/**
 * @typedef {import('lib/graphqlTypeDefs').Video} Video
 * @typedef {import('@nbcnews/gamma').GammaProps} GammaProps
 * @typedef {GammaProps["playlistItem"]} PlaylistItem
 *
 * @typedef {{
 *   format: string;
 *   publicUrl: string;
 *   assetType: string;
 *   assetDuration: number;
 * }} VideoAsset
 *
 * @typedef {{
 *   url: { primary: string };
 *   width?: number;
 *   height?: number;
 *   altText: string;
 * }} VideoImage
 *
 * @typedef {{
 *   id: string;
 *   primaryImage: VideoImage;
 *   teaseImage?: VideoImage;
 *   datePublished?: string;
 *   duration: string;
 *   description: {
 *     primary: string;
 *   };
 *   headline: {
 *     primary: string;
 *   };
 *   mpxMetadata: {
 *     id: string;
 *     externalAdvertiserId: string;
 *   };
 *   publisher: {
 *     name: string;
 *   };
 *   url: {
 *     canonical?: string;
 *     primary: string;
 *   };
 *   closedCaptioning?: {
 *     webvtt?: string;
 *     srt?: string;
 *   };
 *   videoAssets: VideoAsset[];
 *   playable: boolean;
 * }} VideoData
 */

/**
 * The `video` object from MPX is large with a complex schema. Various
 * places in the Gamma Video Player need information from within this object.
 *
 * This function serves the following purposes:
 *
 * 1. It provides a comprehensive list of the data that the Gamma Video Player
 *    component will use from the MPX video object.
 * 2. It allows the Gamma Video Player to be decoupled from the MPX schema. In
 *    other words, it allows asking for supported properties without having
 *    knowledge of where in the schema the value is stored.
 * 3. It deduplicates deep selection and defaulting of the data
 *
 * Note that selectVideoData is not intended to be a general purpose mapper
 * that abstracts over the entire MPX schema for all possible use cases. If such a
 * mapping is needed, and the MPX schema can't simply be changed to suit, then
 * a new function should be created for that purpose and should be used by the
 * `mapStateToProps` function in the top-level components.
 *
 * @param {VideoData} video
 * @param {object} options
 * @param {boolean} options.isLive
 * @param {boolean} options.useMpxParams
 * @param {boolean} options.isEmbedded
 * @param {string} options.vertical
 * @param {import('./getFreewheelCustomSection').ConsentStatus} options.gppStatus
 * @param {object} options.breakpoints
 * @param {boolean} options.breakpoints.isM
 * @param {boolean} options.breakpoints.isL
 * @param {boolean} options.breakpoints.isXL
 * @param {boolean} [options.enableRetina]
 * @returns {{
 *   title: string,
 *   datePublished: string | undefined,
 *   isExpired: boolean,
 *   duration: string,
 *   videoUrl: string,
 *   teaseImageUrl: string,
 *   teaseImageAltText: string,
 *   videoId: string,
 *   playlistItem: PlaylistItem,
 *   publisherName: string,
 * }}
 */
export function selectVideoData(video, {
  useMpxParams, isEmbedded, vertical, isLive, gppStatus, breakpoints = {
    isM: false,
    isL: false,
    isXL: false,
  },
  enableRetina = false,
}) {
  const flavor = getFlavorByBreakpoint(breakpoints);
  const publisherName = video?.publisher?.name ?? 'unknown';
  const title = video?.headline?.primary ?? '';
  const { teaseImageUrl, teaseImageAltText } = selectTeaseImage(video, flavor, enableRetina);
  const videoId = video?.id;
  const videoAssets = video?.videoAssets ?? [];
  const fwassetid = video?.mpxMetadata?.externalAdvertiserId;
  const datePublished = video?.datePublished;
  const canonicalUrl = video?.url?.canonical;
  const primaryUrl = video?.url?.primary;
  const isExpired = video?.playable === false;
  const duration = video?.duration ?? 'PT0S';
  const webvttUrl = video?.closedCaptioning?.webvtt;
  const srtUrl = video?.closedCaptioning?.srt;

  const isMobile = MobileDetection.any();

  const adConfig = getBrandAdConfig({
    vertical,
    isMobile,
    isEmbedded,
    isLiveVideo: isLive,
  });

  const playlistItem = toGammaPlaylistItem({
    gppStatus,
    imageUrl: teaseImageUrl,
    videoId,
    fwassetid,
    videoAssets,
    webvttUrl,
    srtUrl,
    useMpxParams,
    adConfig,
  });

  playlistItem.duration = playlistItem?.duration || timeToSeconds(duration);

  // https://nbcnewsdigital.atlassian.net/browse/BENTO-28329
  if (isLive) {
    playlistItem.duration = 600;
  }

  const videoUrl = canonicalUrl || primaryUrl || '';

  return {
    title,
    datePublished,
    isExpired,
    duration,
    videoUrl,
    playlistItem,
    publisherName,
    videoId,
    teaseImageUrl,
    teaseImageAltText,
  };
}
