/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import volumeLevel0 from 'assets/icons/volume-level-0.svg';

import './styles.themed.scss';

const block = 'videoPlayer';
const VideoUnmuteButton = ({
  isLive, isMini, isRail, onClickUnmute, show, className,
// eslint-disable-next-line arrow-body-style
}) => (show ? (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className={classNames(
      `${block}__unmute`,
      'unmuteTap',
      {
        unmuteTapMini: isMini || isRail,
        isRail,
        isLive,
      },
      className,
    )}
    role="button"
    onClick={onClickUnmute}
    aria-label="Tap To Unmute"
    data-testid="video-unmute-button"
  >
    <div className="unmuteIcon">
      <img className="volumeMutedIcon" src={volumeLevel0} alt="Volume Muted Icon" />
    </div>
  </div>
) : null);

VideoUnmuteButton.propTypes = {
  isLive: PropTypes.bool,
  isMini: PropTypes.bool,
  isRail: PropTypes.bool,
  onClickUnmute: PropTypes.func.isRequired,
  show: PropTypes.bool,
  className: PropTypes.string,
};

VideoUnmuteButton.defaultProps = {
  isLive: false,
  isMini: false,
  isRail: false,
  show: true,
  className: null,
};

export { VideoUnmuteButton };
